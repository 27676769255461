/**
 * Created by René Simon <rene.simon@huchler.it> on 2019-10-03.
 * Copyright © Dr. Huchler und Partner GbR 2019 - present
 */

import {REQUEST_PASSWORD_RESET, REQUEST_SET_PASSWORD} from './consts';

export const requestPasswordResetAction = ({mailAddress, targetAdmin, notifications}) => {
  return {
    type: REQUEST_PASSWORD_RESET,
    mailAddress,
    targetAdmin,
    notifications,
  };
};

export const requestSetPasswordAction = ({history, newPassword, confirmedPassword, notifications}) => {
  return {
    type: REQUEST_SET_PASSWORD,
    history,
    newPassword,
    confirmedPassword,
    notifications,
  };
};

