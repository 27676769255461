/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 03.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */
import React from 'react';
import * as adminOnRest from 'admin-on-rest';
import contentIcon from 'material-ui/svg-icons/av/art-track.js';
import DynamicField from '../../mui/fields/dynamic-field';
import DynamicInput from '../../mui/inputs/dynamic-input';

const {List, Datagrid, Edit, SimpleForm, TextField, EditButton} = adminOnRest;
const log = require('../../libs/utils/log').create('languagesView');

export const ConfigIcon = contentIcon;

export const ConfigList = (props) => {
  log.info('list props', props);
  return (
    <List title="All Configs" {...props}>
      <Datagrid>
        <TextField source="key"/>
        <DynamicField source="value" typeSource="type"/>
        <EditButton basePath="lms/configs"/>
      </Datagrid>
    </List>
  );
};

export const ConfigEdit = (props) => (
  <Edit title="Edit Config" {...props}>
    <SimpleForm>
      <TextField source="key"/>
      <DynamicInput source="value" typeSource="type"/>
    </SimpleForm>
  </Edit>
);
