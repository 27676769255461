/**
 * Created by René Simon <rene.simon@huchler.it> on 2019-10-03.
 * Copyright © Dr. Huchler und Partner GbR 2019 - present
 */

import {all, call, put, takeLatest} from 'redux-saga/effects';
import actionNotification from '../../libs/utils/action-notification';
import {REQUEST_PASSWORD_RESET, REQUEST_SET_PASSWORD} from './consts';
import {notifyAction} from '../notify/actions';
import {plainRequest} from '../../clients/plain';

const log = require('../../libs/utils/log').create('TriggerSage');

function* requestPasswordReset({notifications, mailAddress, targetAdmin}) {
  try {
    log.info(`Action to request password executed`, {notifications, mailAddress});
    yield put(notifyAction({message: actionNotification(notifications).START}));
    yield call(() => {
      return plainRequest({
        method: 'post',
        url: '/password-forgotten/send',
        body: {
          mailAddress,
          targetAdmin,
        },
      });
    });
    yield put(notifyAction({message: actionNotification(notifications).SUCCESS}));
  } catch (error) {
    yield put(notifyAction({message: actionNotification(notifications).FAILURE, error}));
  }
}

function* requestSetPassword({notifications, history, newPassword, confirmedPassword}) {
  try {
    log.info(`Action to set password executed`, {history});
    yield put(notifyAction({message: actionNotification(notifications).START}));
    const {
      location: {
        pathname,
      },
      push,
    } = history;
    const pathSections = pathname.split('/');
    const token = pathSections[pathSections.length-1];
    const result = yield call(() => {
      return plainRequest({
        method: 'put',
        url: `/password-forgotten/set/${token}`,
        body: {
          newPassword,
          confirmedPassword,
        },
      });
    });
    yield put(notifyAction({message: actionNotification(notifications).SUCCESS}));
    const {forwardUrl} = result;
    if (!forwardUrl) {
      yield call(() => push('/login'));
    } else {
      setTimeout(() => {
        window.location = forwardUrl;
      }, 2000);
    }
  } catch (error) {
    yield put(notifyAction({message: actionNotification(notifications).FAILURE, error}));
  }
}

export default function* triggerSaga() {
  yield all([
    takeLatest(REQUEST_PASSWORD_RESET, requestPasswordReset),
    takeLatest(REQUEST_SET_PASSWORD, requestSetPassword),
  ]);
}
