/**
 * Created by Markus Faust <markus.faust@visart.eu> on 8/31/2017.
 * Copyright © Dr. Huchler & Partner 2017
 *
 * Each function here should return a String as a message to indicate a validation error has been found. Each function
 * should return "undefined" if it finds no error. The function will receive the value of the field it is validating
 * as the first  parameter, the value of all other fields as an Object as a second parameter, and the values of
 * any existing records (e.g. in edit mode) as a third parameter *
 */

import validators from '../../libs/utils/validators';

/**
 * Maximum Integer Value Test
 * @param {Number} maxValue
 * @returns {function} which in turn will return a String if validation failed and otherwise undefined
 */
const maxIntValue = (maxValue) => {
  return (fieldValue, allValues, props) => {
    return fieldValue && fieldValue > maxValue ?
      props.translate('ellis.forms.errorMsg.intTooBig', {maxValue: maxValue}) :
      undefined;
  };
};
/**
 * Minimum Integer Value Test
 * @param {Number} minValue
 * @returns {function} which in turn will return a String if validation failed and otherwise undefined
 */
const minIntValue = minValue => (fieldValue, allValues, props) => fieldValue && fieldValue < minValue ?
  props.translate('ellis.forms.errorMsg.intTooSmall', {minValue: minValue}) :
  undefined;


/**
 * Minimum String Length
 * @param {Number} minLength
 * @returns {function} which in turn will return a String if validation failed and otherwise undefined
 */
const minStringLength = (minLength) => {
  return (fieldValue, allValues, props) => {
    return fieldValue && fieldValue.length < minLength ?
      props.translate('ellis.forms.errorMsg.tooFewCharacters', {minLength: minLength}) :
      undefined;
  };
};


/**
 * Maximum String Length
 * @param {Number} maxLength
 * @returns {function} which in turn will return a String if validation failed and otherwise undefined
 */
const maxStringLength = (maxLength) => {
  return (fieldValue, allValues, props) => {
    return fieldValue && fieldValue.length > maxLength ?
      props.translate('ellis.forms.errorMsg.tooManyCharacters', {maxLength: maxLength}) :
      undefined;
  };
};

/**
 * Create validator for email pattern test
 * @param {boolean} [mandatory=false]
 * @return {function}
 */
const validEmailAddress = mandatory => {
  /**
   * Email Address Pattern Test
   * @param {string} fieldValue
   * @param {Object} allValues entered into the form
   * @param {Object} props object of the form component
   * @return {string|undefined} - String ff validation failed
   */
  return (fieldValue, allValues, props) => {
    if (!fieldValue && !mandatory) {
      return;
    }
    if (!validators.isEmailString(fieldValue)) {
      return props.translate('ellis.forms.errorMsg.notAnEmail');
    }
  };
};

/**
 * Validator for language codes
 * @param {string} fieldValue
 * @param {Object} allValues
 * @param {Object} props
 * @return {string|undefined} - String ff validation failed
 */
function validLanguageCode(fieldValue, allValues, props) {
  if (!validators.isLanguageCode(fieldValue)) {
    return props.translate('ellis.forms.errorMsg.notALanguageCode');
  }
}

/**
 * Validator for language codes
 * @param {string} fieldValue
 * @param {Object} allValues
 * @param {Object} props
 * @return {string|undefined} - String ff validation failed
 */
function validNonEmptyString(fieldValue, allValues, props) {
  if (!validators.isNonEmptyString(fieldValue)) {
    return props.translate('ellis.forms.errorMsg.notANonEmptyString');
  }
}

/**
 * Create validator for json strings
 * @param {boolean} [mandatory=false]
 * @return {function}
 */
function validJsonString(mandatory = false) {
  /**
   * Validator for json strings
   * @param {string} fieldValue
   * @param {Object} allValues
   * @param {Object} props
   * @return {string|undefined} - String ff validation failed
   */
  return (fieldValue, allValues, props) => {
    if (!fieldValue && !mandatory) {
      return;
    }
    if (!validators.isJson(fieldValue)) {
      return props.translate('ellis.forms.errorMsg.notValidJson');
    }
  };
}

export {
  maxIntValue,
  minIntValue,
  minStringLength,
  maxStringLength,
  validEmailAddress,
  validLanguageCode,
  validNonEmptyString,
  validJsonString,
};
