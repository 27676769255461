/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on ${DATE}.
 * Copyright © Dr. Huchler & Partner 2017
 */

import React from 'react';
import {Card, CardText} from 'material-ui/Card';
import {ViewTitle} from 'admin-on-rest/lib/mui';
import currentUser from '../../libs/utils/current-user';

const log = require('../../libs/utils/log').create('dashboardView');

/**
 * Dashboard Component
 * @return {xml}
 */
function dashboard() {
  let user = null;
  try {
    user = currentUser.get();
  } catch (error) {
    log.notice('No current user found:', error.message);
  }
  let text = `Please login`;
  if (user) {
    text = `Hello, ${user.firstName} ${user.lastName}`;
  }
  return (
    <Card>
      <ViewTitle title={process.env.NODE_ENV}/>
      <CardText>{text}</CardText>
    </Card>
  );
}

export default dashboard;
