/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 22.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import * as aor from 'admin-on-rest';
import httpClient from '../clients/http';
import system from './utils/system';
import fileTools from './utils/file';

const {jsonServerRestClient} = aor;

const restClient = jsonServerRestClient(system.getApiUrl(), httpClient);

/**
 * Extending the given restClient by the ability to upload files
 * @param {function} restClient
 * @return {function}
 */
function addUploadCapabilities(restClient) {
  return (type, resource, params) => {
    const data = params.data;
    const fileFieldNames = getFileFieldNames(data);
    if (!fileFieldNames.length) {
      return restClient(type, resource, params);
    }

    return Promise.all(fileFieldNames.map(key => loadFiles(data[key]))).then(base64strings => {
      fileFieldNames.forEach((key, index) => {
        data[key] = base64strings[index];
      });
      return data;
    }).then(data => {
      params.data = data;
      return restClient(type, resource, params);
    });
  };
}

/**
 * Get the list of fields in the data which could contain files
 * @param {Object} data
 * @return {Array.<string>}
 */
function getFileFieldNames(data) {
  if (!data) {
    return [];
  }
  return Object.keys(data).filter(key => key.substr(-4) === 'File' || key.substr(-5) === 'Files');
}

/**
 * Returning the files as base64 strings
 * @param {Array.<Object>} value
 * @return {Promise.<Array.<string>>}
 */
function loadFiles(value) {
  if (!value || !value.length) {
    return Promise.resolve([]);
  }
  return Promise.all(
    value.map(v => {
      if (v.rawFile) {
        return Promise.all([
          v.title,
          fileTools.convertFileToBase64(v.rawFile),
        ]);
      }
      return Promise.all([
        v.title,
        v.src,
      ]);
    }),
  ).then(results => {
    return results.map(([title, src]) => ({title, src}));
  });
}

export default addUploadCapabilities(restClient);
