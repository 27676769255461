/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 22.01.18.
 * Copyright © Dr. Huchler und Partner 2017
 */

const log = require('../../libs/utils/log').create('mongoIdsParser');

/**
 * Parsing mongoIds
 * @param {*} value
 * @return {string}
 */
export default function(value) {
  log.info('Parsing mongoIds values', {value});
  return value;
}
