/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 28.11.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as aor from 'admin-on-rest';
import currentUser from '../../libs/utils/current-user';

const {MenuItemLink, translate} = aor;

const Menu = ({resources, onMenuTap, logout, translate}) => {
  const user = currentUser.get();
  if (!user) {
    return null;
  }
  const resourceEntries = resources.map(resource => {
    const path = resource.path;
    const Icon = resource.props.icon;
    const options = resource.props.options || {};
    const labelKey = options.label || `resources.${path}.name`;
    const label = translate(labelKey, {smart_count: 2});
    if (path === 'password-change') {
      return <MenuItemLink
        key={`${path}MenuEntry`}
        to={`/${path}/${user._id}`}
        onClick={onMenuTap}
        primaryText={label}
        leftIcon={Icon ? <Icon/> : null}
      />;
    }
    if (!resource.props.list) {
      return null;
    }
    return <MenuItemLink
      key={`${path}MenuEntry`}
      to={`/${path}`}
      onClick={onMenuTap}
      primaryText={label}
      leftIcon={Icon ? <Icon/> : null}
    />;
  });
  return <div>
    {resourceEntries}
    {logout}
  </div>;
};

const mapStateToProps = state => ({
  resources: getResources(state),
});

/**
 * Returns the registered resources
 * @param {Object} state
 * @return {Array}
 */
function getResources(state = {}) {
  const admin = state.admin || {};
  const resources = admin.resources || {};
  return Object.keys(resources).map(path => ({
    path,
    ...resources[path],
  }));
}

Menu.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.object),
  onMenuTap: PropTypes.func,
  logout: PropTypes.object,
  translate: PropTypes.func,
};

export default connect(mapStateToProps)(translate(Menu));
