/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 04.09.17.
 * Copyright © Dr. Huchler und Partner 2017
 */


/**
 * @typedef {Object} UrlParts
 *
 * @property {string} url
 * @property {string|null} query
 * @property {string|null} hash
 */

/**
 * @typedef {Object} ParamObj
 *
 * @property {string} key
 * @property {string|boolean} value
 * @property {string|null} num
 */

/**
 * @type {Lodash}
 * @private
 */
const _ = require('lodash');

const NEEDLES = ['=', '['];
const SEPARATORS = {
  QUERY: '?',
  PARAMS: '&',
  HASH: '#',
};

const UNKNOWN_HOST = 'unknownHost';

const query = null;

/**
 * Returns the query param for a given key
 * @param {string} [key=null]
 * @return {*}
 */
function getParams(key) {
  const query = getQuery();
  if (!key) {
    return _.cloneDeep(query);
  }
  return _.cloneDeep(query[key]);
}

/**
 * Returns the query object
 * @return {{}}
 */
function getQuery() {
  if (query) {
    return query;
  }

  const queryParts = getQueryParts(getUrlParts());
  if (!queryParts || !queryParts.length) {
    return {};
  }

  const queryPreparation = queryParts.reduce((newQuery, part) => {
    const param = getParamObjByParamPart(part);
    if (!param) {
      return newQuery;
    }
    const {key, value, num} = param;
    if (!newQuery[key]) {
      newQuery[key] = [];
    }
    newQuery[key].push({value, num});
    return newQuery;
  }, {});

  return _.reduce(queryPreparation, (newQuery, values, key) => {
    const value = getValueByParamObjs(values);
    if (!value) {
      return newQuery;
    }
    newQuery[key] = value;
    return newQuery;
  }, {});
}

/**
 * Return the query param strings
 * @param {UrlParts} urlParts
 * @return {[string]}
 */
function getQueryParts(urlParts) {
  if (!urlParts || !urlParts.query) {
    return [];
  }
  return urlParts.query.split(SEPARATORS.PARAMS);
}

/**
 * Returns the url parts
 * @return {null | UrlParts}
 */
function getUrlParts() {
  const location = window.location;
  let url = '';
  if (location) {
    url = location.href || String(location);
  }
  if (!url) {
    return null;
  }

  const split = url.split(SEPARATORS.QUERY);
  const paramFreeUrl = split[0];
  const paramSplit = (split[1] || '').split(SEPARATORS.HASH);
  return {
    url: paramFreeUrl,
    query: paramSplit[0] || null,
    hash: paramSplit[1] || null,
  };
}

/**
 * Returns a param object by its parts
 * @param {string} part
 * @return {ParamObj | null}
 */
function getParamObjByParamPart(part) {
  if (!part || !_.isString(part)) {
    return null;
  }
  const a = part.split('=');
  // in case params look like: list[]=thing1&list[]=thing2
  let num = null;
  const key = a[0].replace(/\[\d*]/, v => {
    num = v.slice(1, -1);
    return '';
  });
  const value = a[1] || true;
  return {key, value, num};
}

/**
 * Returns a value from a given param object
 * @param {[ParamObj]} param
 * @return {*}
 */
function getValueByParamObjs(param) {
  if (!param || !_.isArray(param) || param.length < 1) {
    return null;
  }

  if (param.length === 1) {
    const paramEl = param[0];
    if (paramEl.num === null) {
      return paramEl.value;
    }
    if (!paramEl.num || _.isNumeric(paramEl.num)) {
      return [paramEl.value];
    }
    return {[paramEl.num]: paramEl.value};
  }

  let arrayfy = true;
  let boolify = true;
  let counter = -1;
  const response = _.reduce(param, (memo, paramEl) => {
    counter++;
    if (paramEl.value !== true) {
      boolify = false;
    }
    if (paramEl.num && !_.isNumeric(paramEl.num)) {
      arrayfy = false;
    }
    if (!paramEl.num) {
      memo[counter] = paramEl.value;
      return memo;
    }
    memo[paramEl.num] = paramEl.value;
    return memo;
  }, {});
  if (boolify) {
    return true;
  }
  if (!arrayfy) {
    return response;
  }
  return _.map(
    _.sortBy(
      _.map(
        response,
        (value, key) => {
          return {key, value};
        },
      ),
      'key',
    ),
    'value',
  );
}

/**
 * Sets a param string for a given query key
 * @param {string} key
 * @param {string} value
 */
function setParam(key, value) {
  if (!key || typeof key !== 'string') {
    throw new Error('Given key must be a valid string');
  }
  if (!value || typeof value !== 'string') {
    throw new Error('Given value must be a valid string');
  }
  const urlParts = getUrlParts();
  const queryParts = getQueryParts(urlParts);
  let newQueryParts = [];
  if (queryParts && queryParts.length) {
    newQueryParts = queryParts.filter(queryPart => {
      if (queryPart.indexOf(key) !== 0) {
        return true;
      }
      return NEEDLES.indexOf(queryPart.substr(key.length, 1)) === -1;
    });
  }
  newQueryParts.push(`${key}=${value}`);
  urlParts.query = newQueryParts.join(SEPARATORS.PARAMS);
  window.location = getUrl(urlParts);
}

/**
 * Getting a url from parts
 * @param {UrlParts} urlParts
 * @return {string}
 */
function getUrl(urlParts) {
  let parts = [
    urlParts.url,
  ];
  if (urlParts.query) {
    parts = parts.concat([
      SEPARATORS.QUERY,
      urlParts.query,
    ]);
  }
  if (urlParts.hash) {
    parts = parts.concat([
      SEPARATORS.HASH,
      urlParts.hash,
    ]);
  }
  return parts.join('');
}

/**
 * Returns the protocol part of the current url
 * @return {string} - [https:|http:]
 */
function getHttpProtocol() {
  if (!window) {
    throw new Error('No window to detect http protocol');
  }
  const ssl = String(window.location).indexOf('https:') === 0;
  return ssl ? 'https:' : 'http:';
}

/**
 * Returns the domain of the current page
 * @return {string}
 */
function getDomain() {
  const win = window || {};
  const location = win.location || {};
  return location.hostname || location.host || UNKNOWN_HOST;
}

/**
 * Returns the subdomain part of the domain
 * @param {string} domain
 * @return {string}
 */
function getSubDomainByDomain(domain) {
  if (!domain || typeof domain !== 'string') {
    throw new Error('No valid domain given');
  }
  const pointSections = domain.split('.');
  if (pointSections.length < 3) {
    return '';
  }
  return pointSections.slice(0, pointSections.length - 2).join('.');
}

export default {getParams, setParam, getHttpProtocol, getDomain, getSubDomainByDomain, UNKNOWN_HOST};
