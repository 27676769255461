/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 22.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

export const DOWNLOAD_ACTION = 'DOWNLOAD_ACTION';
export const downloadAction = (notifications, route, source, record) => {
  return {
    type: DOWNLOAD_ACTION,
    notifications,
    route,
    source,
    record,
  };
};
