/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 03.10.17.
 * Copyright © Dr. Huchler & Partner 2017
 */

import _ from './lodash-tools';

const E_MAIL_REGEXP =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

/**
 * Is the value a not empty string?
 * @param {*} value Value to validate
 * @returns {boolean}
 */
function isNonEmptyString(value) {
  return value && _.isString(value);
}

/**
 * Is the value a number?
 * @param {*} value Value to validate
 * @param {boolean} [higherThanZero=false] Only numbers higher than zero allowed
 * @returns {boolean}
 */
function isNumber(value, higherThanZero = false) {
  if (higherThanZero && !value) {
    return false;
  }
  return _.isNumber(value);
}

/**
 * Is the value a boolean?
 * @param {*} value Value to validate
 * @returns {boolean}
 */
function isBoolean(value) {
  return _.isBoolean(value);
}

/**
 * Is the value a function?
 * @param {*} value Value to validate
 * @returns {boolean}
 */
function isFunction(value) {
  return _.isFunction(value);
}

/**
 * Is the value an array?
 * @param {*} value Value to validate
 * @returns {boolean}
 */
function isArray(value) {
  return _.isArray(value);
}

/**
 * Is valid enum value?
 * @param {*} value Value to validate
 * @param {[*]} possibleValues Array of possible values to check against
 * @returns {boolean}
 */
function isEnum(value, possibleValues) {
  if (!possibleValues || !_.isArray(possibleValues)) {
    throw new Error('Possible values must be an array');
  }
  return possibleValues.indexOf(value) !== -1;
}

/**
 * Is valid Date value?
 * @param {*} value Value to validate
 * @returns {boolean}
 */
function isDate(value) {
  return value && value instanceof Date;
}

/**
 * Is valid date string value?
 * @param {*} value Value to validate
 * @returns {boolean}
 */
function isDateString(value) {
  if (!isNonEmptyString(value)) {
    return false;
  }
  return value === (new Date(value)).toISOString();
}

/**
 * Is valid e-mail string value?
 * @param {*} value Value to validate
 * @returns {boolean}
 */
function isEmailString(value) {
  if (!isNonEmptyString(value)) {
    return false;
  }
  return E_MAIL_REGEXP.test(value);
}

/**
 * Returns the RegExp to validate a string for an e-mail
 * @return {RegExp}
 */
function getEmailRegExp() {
  return E_MAIL_REGEXP;
}

/**
 * Is valid plain object value?
 * @param {*} value Value to validate
 * @returns {boolean}
 */
function isObject(value) {
  return _.isPlainObject(value);
}

/**
 * Is valid language code value?
 * @param {*} value Value to validate
 * @returns {boolean}
 */
function isLanguageCode(value) {
  if (!isNonEmptyString(value)) {
    return false;
  }
  if ([2, 5].indexOf(value.length) === -1) {
    return false;
  }
  return /^[a-z]{2}$/.test(value) || /^[a-z]{2}-[A-Z]{2}$/.test(value);
}

/**
 * Validates if the given value is a valid json string
 * @param {string} value
 * @return {boolean}
 */
function isJson(value) {
  if (!isNonEmptyString(value)) {
    return false;
  }
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
}

export default {
  isNonEmptyString,
  isNumber,
  isBoolean,
  isFunction,
  isArray,
  isEnum,
  isDate,
  isDateString,
  isEmailString, getEmailRegExp,
  isObject,
  isLanguageCode,
  isJson,
};
