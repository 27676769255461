/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/13/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */
import {minIntValue, minStringLength} from '../../forms/form-utils/field-validators';
import FIELD_TYPES from '../../consts/field-types';

export default {
  i18nPath: 'resources.content.fields.',
  fields: {
    key: {
      type: FIELD_TYPES.TEXT_FIELD,
      validatorFunctions: minStringLength(1),
    },
    name: {
      type: FIELD_TYPES.LANGUAGE_FIELD,
    },
    typo3groupId: {
      type: FIELD_TYPES.NUMBER,
      validatorFunctions: minIntValue(1),
    },
  },
};
