/**
 * Created by René Simon <rene.simon@huchler.eu> on 06.05.17.
 * Copyright © Dr. Huchler & Partner 2017
 */

/**
 * @typedef {Object} SeverityType
 * @property {string} name
 * @property {string} color
 */

let win = null;
let initLogLevel = null;
let internalConsole = {
  log: () => {
  }, error: () => {
  },
};

const DEFAULT_LOG_LEVEL = 5; // We want that no log message is shown by default in the console

/**
 * @type {Object.<string, SeverityType>}
 */
const SEVERITIES = {
  '-1': {
    name: 'DEBUG',
    color: '#0000FF',
  },
  '0': {
    name: 'INFO',
    color: '#008000',
  },
  '1': {
    name: 'NOTICE',
    color: '#2AA5A5',
  },
  '2': {
    name: 'WARN',
    color: '#FFA500',
  },
  '3': {
    name: 'ERROR',
    color: '#800080',
  },
  '4': {
    name: 'FATAL',
    color: '#FF0000',
  },
};

/**
 * Initialize the log
 * @param {number} injectedLogLevel
 * @param {Window} injectedWindow
 */
export function init(injectedLogLevel, injectedWindow) {
  initLogLevel = parseInt(injectedLogLevel, 10);
  win = injectedWindow;
  if (win && win.console) {
    internalConsole = win.console;
  } else if (typeof console !== 'undefined') {
    internalConsole = console;
  }
}

/**
 * Get url param
 * @param {string} name
 * @return {string | undefined | boolean | null}
 */
function getParameterByName(name) {
  if (typeof win === 'undefined') {
    return null;
  }
  if (typeof win.location === 'undefined') {
    return null;
  }
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(win.location.search || '');
  return results === null ? false : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

/**
 * Preparing an array to log
 * @param {string} date
 * @param {SeverityType} type
 * @param {string} name
 * @param {*} data
 * @return {Array.<string>}
 */
function prepareLogArray(date, type, name, data) {
  return [
    '%c [' + date + '] ' +
    '%c [' + type.name + '] ' +
    '%c [' + name + '] ',
    'color: #666',
    'font-weight:bold; color: ' + type.color,
    'font-weight:bold; color: #940060',
  ].concat(data);
}

/**
 * Getting the log data
 * @param {string} name
 * @param {number} logLevel
 * @return {function(*=, *=)}
 */
function getLogData(name, logLevel) {
  return (severity, data) => {
    if (severity < logLevel) {
      return;
    }
    const type = SEVERITIES[String(severity)] || {
      name: 'UNKNOWN',
      color: 'bgCyan',
    };
    const logData = prepareLogArray((new Date()).toISOString(), type, name, data);
    if (severity > 2) {
      internalConsole.error(...logData);
    } else {
      internalConsole.log(...logData);
    }
  };
}

/**
 * Checks if a given log level is valid
 * @param {number} logLevel
 * @return {boolean}
 */
function logLevelIsValid(logLevel) {
  return !isNaN(logLevel) && logLevel >= -1 && logLevel < 5;
}

/**
 * Returns the log level
 * @param {number} givenLogLevel
 * @return {number}
 */
function getLogLevel(givenLogLevel) {
  let logLevel = parseInt(givenLogLevel, 10);

  if (logLevelIsValid(logLevel)) {
    return logLevel;
  }

  logLevel = initLogLevel;

  if (logLevelIsValid(logLevel)) {
    return logLevel;
  }

  logLevel = parseInt(getParameterByName('logLevel'), 10);

  if (logLevelIsValid(logLevel)) {
    return logLevel;
  }

  return DEFAULT_LOG_LEVEL;
}

/**
 * Logger
 *
 * @param {string} name Name of the logger
 * @param {number} initLogLevel severity level of this logger
 * @constructor
 */
const Logger = function(name, initLogLevel) {
  name = name || 'anonymous';

  const logLevel = getLogLevel(initLogLevel);

  const logData = getLogData(name, logLevel);

  this.debug = (...args) => {
    logData(-1, args);
  };

  this.info = (...args) => {
    logData(0, args);
  };

  this.notice = (...args) => {
    logData(1, args);
  };

  this.warn = (...args) => {
    logData(2, args);
  };

  this.error = (...args) => {
    logData(3, args);
  };

  this.fatal = (...args) => {
    logData(4, args);
  };
};

/**
 * Returns a log
 * @param {Array} args
 * @return {Logger}
 */
export function getLog(...args) {
  return new Logger(...args);
}
