/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 05.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import CONFIG_TYPES from '../../consts/config-types';
import LanguageObjectField from './language-object-field';
import * as aor from 'admin-on-rest';

const {TextField, BooleanField} = aor;

const DynamicField = ({record = {}, source, typeSource}) => {
  const type = record[typeSource];
  if (!type) {
    return <div>Could not determine type</div>;
  }
  if (type === CONFIG_TYPES.LANGUAGE_OBJECT) {
    return <LanguageObjectField record={record} source={source}/>;
  }
  if (type === CONFIG_TYPES.STRING) {
    return <TextField record={record} source="value"/>;
  }
  if (type === CONFIG_TYPES.BOOLEAN) {
    return <BooleanField record={record} source="value"/>;
  }
  return <div>{`Unsupported type "${type}"`}</div>;
};

DynamicField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  typeSource: PropTypes.string.isRequired,
};

export default DynamicField;
