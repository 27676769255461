/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on ${DATE}.
 * Copyright © Dr. Huchler & Partner 2017
 */

import * as adminOnRest from 'admin-on-rest';
import axios from 'axios';
import currentUser from '../libs/utils/current-user';
import {initLanguages} from '../libs/utils/languages';
import system from '../libs/utils/system';
import {initConfig, isConfigInitialized, resetConfig} from '../libs/utils/config';

const log = require('../libs/utils/log').create('authClient');

const TOKEN_KEY = system.getLoginKey();

const {AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS} = adminOnRest;
// TODO crypto password before sending over the wire

const AUTH_URL = system.getApiUrl();

const auth = axios.create({
  baseURL: AUTH_URL,
  withCredentials: true,
  auth: {
    username: process.env.REACT_APP_API_USERNAME,
    password: process.env.REACT_APP_API_PASSWORD,
  },
});

function setToken(data) {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(data));
}

function removeToken() {
  localStorage.removeItem(TOKEN_KEY);
}

function getToken() {
  const result = localStorage.getItem(TOKEN_KEY);
  if (!result) {
    return null;
  }
  let token = null;
  try {
    token = JSON.parse(result);
  } catch (error) {
    log.notice('Could not parse token', error.message);
  }
  return token;
}

async function login({username, password}) {
  await auth.post('/login', {
    username,
    password,
  });
  log.info('Logged in');
  const response = await auth.get('/users/current');
  const user = response.data;
  log.info('Got current user', user);
  await Promise.all([
    initConfig(auth),
    initLanguages(auth),
  ]);
  currentUser.injectCurrentUser(user);
  setToken(user);
}

async function logout() {
  try {
    removeToken();
    resetConfig();
    await auth.post('/logout');
    currentUser.injectCurrentUser(null);
    log.info('Logged out on server');
  } catch (error) {
    const {response: {status} = {}} = error || {};
    if (status !== 401) {
      log.error('Failed to logout on server', JSON.stringify(error, null, '  '));
    } else {
      log.error('Error in logout process', error);
    }
  }
}

async function check(allowNoUser = false) {
  const user = getToken();
  if (!user) {
    if (allowNoUser) {
      return;
    }
    throw new Error(`No user or token`);
  }
  log.info('Received user from token', {user});
  currentUser.injectCurrentUser(user);
  await initLanguages(auth);
  if (isConfigInitialized()) {
    return;
  }
  await initConfig(auth);
}

async function getPermissions() {
  await check(true);
  const user = currentUser.get();
  if (!user) {
    return {
      isAdmin: () => false,
      hasRight: () => false,
    };
  }
  return {
    isAdmin: () => !!user.isAdmin,
    hasRight: right => {
      if (user.isAdmin) {
        return true;
      }
      if (!user.rights) {
        return false;
      }
      return user.rights.indexOf(right) !== -1;
    },
  };
}

async function handleError({status}) {
  log.error('Received error in auth client', {status});
  if (status === 401 || status === 403) {
    removeToken();
    throw new Error(`Invalid login`);
  }
}

export default async (type, params) => {
  try {
    const {username, password, message: {status} = {}} = params || {};
    log.debug('Auth client request', {type, params});
    if (type === AUTH_LOGIN) {
      return await login({username, password});
    }
    if (type === AUTH_LOGOUT) {
      return await logout();
    }
    if (type === AUTH_CHECK) {
      return await check();
    }
    if (type === AUTH_GET_PERMISSIONS) {
      return await getPermissions();
    }
    if (type === AUTH_ERROR) {
      return await handleError({status});
    }
    throw new Error('Unknown type');
  } catch (error) {
    log.error('Failed to execute main login', error);
    throw error;
  }
};
