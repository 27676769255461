/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on ${DATE}.
 * Copyright © Dr. Huchler & Partner 2017
 */
import axios from 'axios';
import _ from '../libs/utils/lodash-tools';

const log = require('../libs/utils/log').create('httpRequest');

const axiosClient = axios.create({
  withCredentials: true,
  auth: {
    username: process.env.REACT_APP_API_USERNAME,
    password: process.env.REACT_APP_API_PASSWORD,
  },
});

/**
 * http request function
 * @param {string} url
 * @param {{
 *   method: string,
 *   body: *
 * }} options
 * @return {Promise.<*>}
 */
function request(url, options = {}) {
  log.info('Request', {url, options});
  return new Promise((resolve, reject) => {
    try {
      options.method = options.method || 'GET';
      axiosClient[options.method.toLowerCase()](url, getBody(options.body))
        .then(response => processResponse(response))
        .then(resolve)
        .catch(error => {
          reject(convertError(error));
        });
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Returns a clean body
 * @param {*} data
 * @return {*}
 */
function getBody(data) {
  if (!data) {
    return null;
  }
  let body = _.clone(data);
  if (typeof body === 'string') {
    body = JSON.parse(body);
  }
  if (!_.isPlainObject(body)) {
    return body;
  }
  if (body.id) {
    body._id = body.id;
    delete body.id;
  }
  return body;
}

/**
 * Processes the response of the service
 * @param {*} response
 * @return {Promise.<*>}
 */
function processResponse(response) {
  // TODO maybe change?
  response.headers.has = (headerName) => _.has(response.headers, headerName);
  response.headers.get = (headerName) => response.headers[headerName];
  response.json = response.data;
  if (_.isArray(response.json)) {
    response.json = _.map(response.json, entry => {
      if (entry._id) {
        entry.id = entry._id;
        delete entry._id;
      }
      if (entry.staffNumber && entry.firstName && entry.lastName) {
        entry.nameAndStaffNumber = entry.firstName + ' ' + entry.lastName + ' (' + entry.staffNumber + ')';
      }
      return entry;
    });
  } else if (_.isObject(response.json)) {
    if (response.json._id) {
      response.json.id = response.json._id;
      delete response.json._id;
    }
  }
  return response;
}

/**
 * Converts the given error to an error that should be return by the http client
 * @param {Error|AxiosError} error
 * @return {Error}
 */
function convertError(error) {
  if (!error.response || !error.response.data) {
    log.error('Got unknown request error', error);
    return error;
  }
  /**
   * @type {EllisApiErrorBody}
   */
  const body = error.response.data;
  log.error('Got error from request', {
    status: error.response.status,
    body: body,
    request: error.config,
  });
  let errorMessage = body.error || 'No error message given';
  if (body.additionInformation) {
    body.additionInformation.forEach(information => {
      errorMessage += `\n- property: "${information.property}" reason: "${information.type}"`;
    });
  }
  const e = new Error(errorMessage);
  e.status = error.response.status;
  return e;
}

export default request;
