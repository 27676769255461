/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 22.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

export default notificationPath => {
  const extend = postfix => `${notificationPath}.${postfix}`;
  return {
    START: extend('start'),
    SUCCESS: extend('success'),
    FAILURE: extend('failure'),
  };
};
