/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 03.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */
import React from 'react';
import * as adminOnRest from 'admin-on-rest';
import contentIcon from 'material-ui/svg-icons/av/art-track.js';
import schema from './schema';
import formGenerator from '../../forms/form-generator';

const {List, Datagrid, Edit, Create, SimpleForm, TextField, BooleanField, EditButton} = adminOnRest;
const log = require('../../libs/utils/log').create('languagesView');

export const LanguageIcon = contentIcon;

export const LanguageList = (props) => {
  log.info('list props', props);
  return (
    <List title="All Languages" {...props}>
      <Datagrid>
        <TextField source="code"/>
        <TextField source="name"/>
        <BooleanField source="default"/>
        <EditButton basePath="lms/languages"/>
      </Datagrid>
    </List>
  );
};

export const LanguageEdit = (props) => (
  <Edit title="Edit Language" {...props}>
    <SimpleForm>
      {formGenerator.getInputFieldsBySchema(schema)}
    </SimpleForm>
  </Edit>
);


export const LanguageCreate = (props) => {
  return (
    <Create title="Create Language" {...props}>
      <SimpleForm>
        {formGenerator.getInputFieldsBySchema(schema)}
      </SimpleForm>
    </Create>
  );
};
