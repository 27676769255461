/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 05.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import TranslatedLabel from '../utils/translated-label';

const {Link} = require('react-router-dom');

/**
 * Generates the input field for the config value
 */
class LinkButton extends Component {
  /**
   * Standard render function
   * @return {xml}
   */
  render() {
    const props = this.props;
    const {record, source, route, icon, label, referrer} = props;
    let path = route;
    if (source) {
      path = route.replace('#{value}', record[source]);
    }
    const to = {
      pathname: path,
      state: {referrer},
    };
    return <FlatButton
      {...props}
      primary
      label={<TranslatedLabel label={label}/>}
      icon={icon}
      containerElement={<Link to={to} />}
      style={{overflow: 'inherit'}}/>;
  }
}

LinkButton.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  route: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  referrer: PropTypes.string,
};

export default LinkButton;
