/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on ${DATE}.
 * Copyright © Dr. Huchler & Partner 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import * as adminOnRest from 'admin-on-rest';
import _ from '../../libs/utils/lodash-tools';
import userIcon from 'material-ui/svg-icons/social/group';
import schema from './schema';
import formGenerator from '../../forms/form-generator';
import FileUploadIcon from 'material-ui/svg-icons/file/file-upload';
import FileDownloadIcon from 'material-ui/svg-icons/file/file-download';
import LinkButton from '../../mui/buttons/link-button';
import DownloadButton from '../../mui/buttons/download-button';
import ELLIS_RIGHTS from 'ellis-rights';
/* MF - The following two imports are for the password-reset button, should it be implemented here:*/
import SendIcon from 'material-ui/svg-icons/content/send';
import TriggerButton from '../../mui/buttons/trigger-button';


const {
  List, Datagrid, Edit, Create, SimpleForm, DateField, TextField, EditButton,
  DisabledInput, WithPermission, Filter, TextInput,
} = adminOnRest;

export const UserIcon = userIcon;

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="resources.users.filter.username" source="username"/>
    <TextInput label="resources.users.filter.staffNumber" source="staffNumber"/>
    <TextInput label="resources.users.filter.firstName" source="firstName"/>
    <TextInput label="resources.users.filter.middleName" source="middleName"/>
    <TextInput label="resources.users.filter.lastName" source="lastName"/>
    <TextInput label="resources.users.filter.businessEmail" source="businessEmail"/>
    <TextInput label="resources.users.filter.privateEmail" source="privateEmail"/>
  </Filter>
);

export const UserList = (props) => {
  return (
    <div>
      <WithPermission resolve={({permissions}) => permissions.hasRight(ELLIS_RIGHTS.USER_IMPORT)}>
        <LinkButton
          route="/lms/users-import/create"
          label="resources.users.buttons.usersImport"
          icon={<FileUploadIcon/>}
        />
      </WithPermission>
      <WithPermission resolve={({permissions}) => permissions.hasRight(ELLIS_RIGHTS.USER_EXPORT)}>
        <DownloadButton
          notifications="resources.users.actions.usersExport"
          route="/export-users"
          label="resources.users.buttons.usersExport"
          icon={<FileDownloadIcon/>}
        />
      </WithPermission>
      <List
        title="All Users"
        filters={<UserFilter/>}
        {...props}
      >
        {permissions => {
          const COLUMNS = [
            <TextField key="col.username" source="username"/>,
            <TextField key="col.firstName" source="firstName"/>,
            <TextField key="col.lastName" source="lastName"/>,
            <TextField key="col.staffNumber" source="staffNumber"/>,
            <DateField key="col.updateDate" source="updateDate"/>,
            <TextField key="col.id" source="id"/>,
          ];
          if (permissions.hasRight(ELLIS_RIGHTS.TRAINING_CONTENT_UPDATE)) {
            COLUMNS.push(<EditButton key="col.button.edit" basePath="users"/>);
          }
          /* MF - Here is a working Reset Password Button That just needs an email to be programmed that does not
          require a training or participation. This may require a new specific reduced email Layout and emailAccesData
          template:*/
          if (permissions.hasRight(ELLIS_RIGHTS.USER_UPDATE)) {
            COLUMNS.push(
              <TriggerButton
                key="col.button.resetPassword"
                notifications="resources.users.actions.resetPassword"
                route="lms/users/#{value}/reset-password"
                source="id"
                label="resources.users.buttons.resetPassword"
                icon={<SendIcon/>}
                refreshAfter={false}
                sortable={false}
              />);
          }
          return <Datagrid>{COLUMNS}</Datagrid>;
        }}
      </List>
    </div>
  );
};

export const UserTitle = ({record}) => {
  return <span>User {record ? `"${record.label}"` : ''}</span>;
};

UserTitle.propTypes = {
  record: PropTypes.shape({
    title: PropTypes.string.isRequired,
    label: PropTypes.string,
  }).isRequired,
};

export const UserEdit = (props) => (
  <Edit title="Edit User" {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      {formGenerator.getInputFieldsBySchema(_.omit(schema, 'password'))}
    </SimpleForm>
  </Edit>
);

export const UserCreate = (props) => {
  return (
    <Create title="Create a User" {...props}>
      <SimpleForm>
        {formGenerator.getInputFieldsBySchema(schema)}
      </SimpleForm>
    </Create>
  );
};

