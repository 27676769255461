/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 14.02.18.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React, {Component, createElement} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import autoprefixer from 'material-ui/utils/autoprefixer';
import CircularProgress from 'material-ui/CircularProgress';
import withWidth from 'material-ui/utils/withWidth';
import compose from 'recompose/compose';
import {defaultTheme, Notification, setSidebarVisibility as setSidebarVisibilityAction, Sidebar} from 'admin-on-rest';

import Menu from './Menu';
import AppBar from './AppBar';
import {getCurrentUser} from '../../libs/utils/current-user';

const styles = {
  wrapper: {
    // Avoid IE bug with Flexbox, see #467
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  body: {
    backgroundColor: '#edecec',
    display: 'flex',
    flex: '1 1 auto',
    overflowY: 'hidden',
    overflowX: 'scroll',
  },
  bodySmall: {
    backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    padding: '2em',
  },
  contentSmall: {
    flex: 1,
    paddingTop: '3em',
  },
  loader: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 16,
    zIndex: 1200,
  },
};

const prefixedStyles = {};

/**
 * Default layout
 */
class Layout extends Component {
  constructor(...args) {
    super(...args);
    if (this.props.width !== 1) {
      this.props.setSidebarVisibility(true);
    }
  }

  /**
   * Default render function
   * @return {xml}
   */
  render() {
    const {
      children,
      dashboard,
      isLoading,
      logout,
      menu,
      theme,
      title,
      width,
    } = this.props;
    const muiTheme = getMuiTheme(theme);
    if (!prefixedStyles.main) {
      // do this once because user agent never changes
      const prefix = autoprefixer(muiTheme);
      prefixedStyles.wrapper = prefix(styles.wrapper);
      prefixedStyles.main = prefix(styles.main);
      prefixedStyles.body = prefix(styles.body);
      prefixedStyles.bodySmall = prefix(styles.bodySmall);
      prefixedStyles.content = prefix(styles.content);
      prefixedStyles.contentSmall = prefix(styles.contentSmall);
    }
    const user = getCurrentUser();
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div style={prefixedStyles.wrapper}>
          <div style={prefixedStyles.main}>
            {width !== 1 && <AppBar title={title} showMenuButton={!!user}/>}
            <div
              className="body"
              style={
                width === 1 ? (
                  prefixedStyles.bodySmall
                ) : (
                  prefixedStyles.body
                )
              }
            >
              <div
                style={
                  width === 1 ? (
                    prefixedStyles.contentSmall
                  ) : (
                    prefixedStyles.content
                  )
                }
              >
                {children}
              </div>
              {user ? <Sidebar theme={theme}>
                {createElement(menu || Menu, {
                  logout,
                  hasDashboard: !!dashboard,
                })}
              </Sidebar> : undefined}
            </div>
            <Notification/>
            {isLoading && (
              <CircularProgress
                className="app-loader"
                color="#fff"
                size={width === 1 ? 20 : 30}
                thickness={2}
                style={styles.loader}
              />
            )}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

const componentPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
]);

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  dashboard: componentPropType,
  isLoading: PropTypes.bool.isRequired,
  logout: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
  ]),
  menu: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  setSidebarVisibility: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.number,
};

Layout.defaultProps = {
  theme: defaultTheme,
};

/**
 * Map the state to props
 * @param {Object} state
 * @return {{isLoading: boolean}}
 */
function mapStateToProps(state) {
  return {
    isLoading: state.admin.loading > 0,
  };
}

const enhance = compose(
  connect(mapStateToProps, {
    setSidebarVisibility: setSidebarVisibilityAction,
  }),
  withWidth(),
);

export default enhance(Layout);
