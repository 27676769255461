/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 05.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getDefaultLanguageCode} from '../../libs/utils/languages';

/**
 * Generates the input field for the config value
 */
class LanguageObjectField extends Component {
  /**
   * Standard render function
   * @return {xml}
   */
  render() {
    const props = this.props;
    const {record, source, state} = props;
    const defaultLanguage = getDefaultLanguageCode();
    const language = state.locale;
    let output = 'Not set';
    const value = record[source];
    if (value) {
      output = value[language] || value[defaultLanguage] || output;
    }
    return <div>{output}</div>;
  }
}

LanguageObjectField.propTypes = {
  state: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default connect((state) => {
  return {state: state};
})(LanguageObjectField);
