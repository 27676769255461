/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 05.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as aor from 'admin-on-rest';

const {FunctionField} = aor;

/**
 * Generates the input field for the config value
 */
class LengthField extends Component {
  /**
   * Standard render function
   * @return {xml}
   */
  render() {
    const props = this.props;
    const {source, label, record} = props;
    return <FunctionField label={label} record={record} render={record => (record[source] || []).length}/>;
  }
}

LengthField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default LengthField;
