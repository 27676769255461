/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 09.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import url from './url';

const DYNAMIC_NEEDLE = '#{partnerName}';
const PARTNER_NAME_TEST = /^([A-Za-z\-_]+)-admin$/i;
const BASIC_AUTH = {
  USERNAME: process.env.REACT_APP_API_USERNAME,
  PASSWORD: process.env.REACT_APP_API_PASSWORD,
};

let loginKey = null;
let name = null;
let partnerName = null;
let apiUrl = null;

/**
 * Checks if the value qualifies for dynamic partner adaption
 * @param {string} str
 * @return {boolean}
 */
function isDynamic(str) {
  return str.indexOf(DYNAMIC_NEEDLE) !== -1;
}

/**
 * Replaces the dynamic part of the string with the given value
 * @param {string} str
 * @param {string} value
 * @return {string}
 */
function replace(str, value) {
  return str.split(DYNAMIC_NEEDLE).join(value);
}

/**
 * Returns the partner name or throws an error if the partner name can't be determined
 * @return {string}
 */
function getPartnerName() {
  if (partnerName) {
    return partnerName;
  }
  const domain = url.getDomain();
  if (domain === url.UNKNOWN_HOST) {
    throw new Error('Could not determine partner name du to unknown domain');
  }
  const subdomain = url.getSubDomainByDomain(domain);
  if (!PARTNER_NAME_TEST.test(subdomain)) {
    throw new Error('Could not determine partner name du to invalid subdomain');
  }
  return partnerName = subdomain.replace(PARTNER_NAME_TEST, '$1');
}

/**
 * Returns the api url
 * @param {boolean} [withBasicAuth=false]
 * @return {string}
 */
function getApiUrl(withBasicAuth = false) {
  if (apiUrl) {
    if (!withBasicAuth) {
      return apiUrl;
    }
    return addBasicAuthToUrl(apiUrl);
  }
  const baseUrl = process.env.REACT_APP_API_URL;
  if (!isDynamic(baseUrl)) {
    apiUrl = baseUrl;
  } else {
    const partnerName = getPartnerName();
    apiUrl = replace(baseUrl, partnerName);
  }
  if (withBasicAuth) {
    return addBasicAuthToUrl(apiUrl);
  }
  return apiUrl;
}

/**
 * Adds the basic auth part to the url
 * @param {string} url
 * @return {string}
 */
function addBasicAuthToUrl(url) {
  return url.replace(/^([a-x]{3,5}:\/\/)/, `$1${BASIC_AUTH.USERNAME}:${BASIC_AUTH.PASSWORD}@`);
}

/**
 * Returns the login key
 * @return {string}
 */
function getLoginKey() {
  if (loginKey) {
    return loginKey;
  }
  const key = process.env.REACT_APP_LOGIN_KEY;
  if (!isDynamic(key)) {
    return loginKey = key;
  }
  const partnerName = getPartnerName();
  return loginKey = replace(key, partnerName);
}

/**
 * Returns the system name
 * @return {string}
 */
function getName() {
  if (name) {
    return name;
  }
  const appName = process.env.REACT_APP_NAME;
  if (!isDynamic(appName)) {
    return name = appName;
  }
  const partnerName = getPartnerName();
  return name = replace(appName, partnerName.toUpperCase());
}

export default {getApiUrl, getLoginKey, getName};
