/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on ${DATE}.
 * Copyright © Dr. Huchler & Partner 2017
 */
import React from 'react';
import './App.css';
import * as adminOnRest from 'admin-on-rest';
import englishMessages from 'admin-on-rest';
import Layout from './mui/layout/Layout';
import Menu from './mui/layout/Menu';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {ellisTheme} from './styles/ellis-mui-theme';
import aorMessageDE from 'aor-language-german';
import {ellisMessagesDE} from './i18n/ellisMessagesDE';
import {ellisMessagesEN} from './i18n/ellisMessagesEN';
import {UserCreate, UserEdit, UserIcon, UserList} from './views/users';
import {TrainingCreate, TrainingEdit, TrainingIcon, TrainingList} from './views/trainings';
import {ContentCreate, ContentEdit, ContentIcon, ContentList} from './views/content';
import {TestCreate, TestEdit, TestIcon, TestList} from './views/tests';
import {TagCreate, TagEdit, TagIcon, TagList, PropertyTagList} from './views/tags';
import {TanCreate, TanIcon, TanList} from './views/tans';
import {GroupCreate, GroupEdit, GroupIcon, GroupList, GroupShow} from './views/groups';
import {ReminderCreate, ReminderEdit, ReminderIcon, ReminderList} from './views/reminders';
import {ParticipantsCreate, ParticipantsEdit, ParticipantsIcon, ParticipantsShow} from './views/participants';
import ParticipationsList, {ParticipationsIcon} from './views/participations';
import LoginPage from './views/login';
import {
  UsersImportForm,
  UsersImportIcon,
  UsersImportShow,
  UsersImportList,
  UsersImportEntryShow,
  UsersImportTagEntryShow,
  UsersImportTrainingEntryShow,
} from './views/users-import';
import {LanguageCreate, LanguageEdit, LanguageIcon, LanguageList} from './views/languages';
import {ConfigEdit, ConfigIcon, ConfigList} from './views/configs';
import {PasswordChangeEdit, PasswordChangeIcon} from './views/password-change';
import Dashboard from './dashboard/container';
import authClient from './clients/auth';
import restClient from './libs/rest-client';
import {createBrowserHistory} from 'history';
import {getSelectedLanguageCode} from './libs/utils/languages';
import system from './libs/utils/system';
import {objectMerger} from './libs/utils/object-merge';
import ELLIS_RIGHTS from 'ellis-rights';
import {customRoutes} from './customRoutes';
import {customSagas} from './customSagas';

const history = createBrowserHistory();
const {Admin, Resource, Delete} = adminOnRest;

/** i18n*/
const combinedGermanMsgs = objectMerger(aorMessageDE, ellisMessagesDE);
const combinedEnglishMsgs = objectMerger(englishMessages, ellisMessagesEN);

const messages = {
  de: combinedGermanMsgs,
  en: combinedEnglishMsgs,
};

const RESOURCES = [
  {
    key: 'trainingsResource',
    name: 'lms/trainings',
    options: {label: 'resources.trainings.name'},
    icon: TrainingIcon,
    list: {right: ELLIS_RIGHTS.TRAINING_LIST, action: TrainingList},
    edit: {right: ELLIS_RIGHTS.TRAINING_UPDATE, action: TrainingEdit},
    create: {right: ELLIS_RIGHTS.TRAINING_CREATE, action: TrainingCreate},
    remove: {right: ELLIS_RIGHTS.TRAINING_DELETE, action: Delete},
  }, {
    key: 'contentResource',
    name: 'lms/content',
    options: {label: 'resources.content.name'},
    icon: ContentIcon,
    list: {right: ELLIS_RIGHTS.TRAINING_CONTENT_LIST, action: ContentList},
    edit: {right: ELLIS_RIGHTS.TRAINING_CONTENT_UPDATE, action: ContentEdit},
    create: {right: ELLIS_RIGHTS.TRAINING_CONTENT_CREATE, action: ContentCreate},
    remove: {right: ELLIS_RIGHTS.TRAINING_CONTENT_DELETE, action: Delete},
  }, {
    key: 'testsResource',
    name: 'lms/tests',
    options: {label: 'resources.tests.name'},
    icon: TestIcon,
    list: {right: ELLIS_RIGHTS.TRAINING_TEST_LIST, action: TestList},
    edit: {right: ELLIS_RIGHTS.TRAINING_TEST_UPDATE, action: TestEdit},
    create: {right: ELLIS_RIGHTS.TRAINING_TEST_CREATE, action: TestCreate},
    remove: {right: ELLIS_RIGHTS.TRAINING_TEST_DELETE, action: Delete},
  }, {
    key: 'remindersResource',
    name: 'lms/reminders',
    options: {label: 'resources.reminders.name'},
    icon: ReminderIcon,
    list: {right: ELLIS_RIGHTS.TRAINING_REMINDER_LIST, action: ReminderList},
    edit: {right: ELLIS_RIGHTS.TRAINING_REMINDER_UPDATE, action: ReminderEdit},
    create: {right: ELLIS_RIGHTS.TRAINING_REMINDER_CREATE, action: ReminderCreate},
    remove: {right: ELLIS_RIGHTS.TRAINING_REMINDER_DELETE, action: Delete},
  }, {
    key: 'participantsResource',
    name: 'lms/participants',
    options: {label: 'resources.participants.name'},
    icon: ParticipantsIcon,
    show: {right: ELLIS_RIGHTS.PARTICIPANTS_SHOW, action: ParticipantsShow},
    edit: {right: ELLIS_RIGHTS.PARTICIPANTS_UPDATE, action: ParticipantsEdit},
    create: {right: ELLIS_RIGHTS.PARTICIPATION_CREATE, action: ParticipantsCreate},
  }, {
    key: 'participationsResource',
    name: 'lms/participations',
    options: {label: 'resources.participations.name'},
    icon: ParticipationsIcon,
    edit: {right: ELLIS_RIGHTS.PARTICIPATION_LIST, action: ParticipationsList},
  }, {
    key: 'usersResource',
    name: 'users',
    options: {label: 'resources.users.name'},
    icon: UserIcon,
    list: {right: ELLIS_RIGHTS.USER_LIST, action: UserList},
    edit: {right: ELLIS_RIGHTS.USER_UPDATE, action: UserEdit},
    create: {right: ELLIS_RIGHTS.USER_CREATE, action: UserCreate},
    remove: {right: ELLIS_RIGHTS.USER_DELETE, action: Delete},
  }, {
    key: 'usersImportResource',
    name: 'imports',
    options: {label: 'resources.usersImport.name'},
    icon: UsersImportIcon,
    show: {right: ELLIS_RIGHTS.USER_IMPORT, action: UsersImportShow},
    list: {right: ELLIS_RIGHTS.USER_IMPORT, action: UsersImportList},
    create: {right: ELLIS_RIGHTS.USER_IMPORT, action: UsersImportForm},
    remove: {right: ELLIS_RIGHTS.USER_IMPORT, action: Delete},
  }, {
    key: 'usersImportEntryResource',
    name: 'import-entries',
    options: {label: 'resources.importEntry.name'},
    icon: UsersImportIcon,
    show: {right: ELLIS_RIGHTS.USER_IMPORT, action: UsersImportEntryShow},
    remove: {right: ELLIS_RIGHTS.USER_IMPORT, action: Delete},
  }, {
    key: 'usersImportTrainingEntryResource',
    name: 'import-training-entries',
    options: {label: 'resources.importTrainingEntry.name'},
    icon: UsersImportIcon,
    show: {right: ELLIS_RIGHTS.USER_IMPORT, action: UsersImportTrainingEntryShow},
    remove: {right: ELLIS_RIGHTS.USER_IMPORT, action: Delete},
  }, {
    key: 'usersImportTagEntryResource',
    name: 'import-tag-entries',
    options: {label: 'resources.importTagEntry.name'},
    icon: UsersImportIcon,
    show: {right: ELLIS_RIGHTS.USER_IMPORT, action: UsersImportTagEntryShow},
    remove: {right: ELLIS_RIGHTS.USER_IMPORT, action: Delete},
  }, {
    key: 'groupsResource',
    name: 'groups',
    options: {label: 'resources.groups.name'},
    icon: GroupIcon,
    show: {right: ELLIS_RIGHTS.GROUP_SHOW, action: GroupShow},
    list: {right: ELLIS_RIGHTS.GROUP_LIST, action: GroupList},
    edit: {right: ELLIS_RIGHTS.GROUP_UPDATE, action: GroupEdit},
    create: {right: ELLIS_RIGHTS.GROUP_CREATE, action: GroupCreate},
    remove: {right: ELLIS_RIGHTS.GROUP_DELETE, action: Delete},
  }, {
    key: 'tagsResource',
    name: 'tags',
    options: {label: 'resources.tags.name'},
    icon: TagIcon,
    list: {right: ELLIS_RIGHTS.TAG_LIST, action: TagList},
    edit: {right: ELLIS_RIGHTS.TAG_UPDATE, action: TagEdit},
    create: {right: ELLIS_RIGHTS.TAG_CREATE, action: TagCreate},
    remove: {right: ELLIS_RIGHTS.TAG_DELETE, action: Delete},
  }, {
    key: 'tagsResource',
    name: 'property-tags',
    options: {label: 'resources.property-tags.name'},
    icon: TagIcon,
    list: {right: ELLIS_RIGHTS.TAG_LIST, action: PropertyTagList},
    edit: {right: ELLIS_RIGHTS.TAG_UPDATE, action: TagEdit},
    create: {right: ELLIS_RIGHTS.TAG_CREATE, action: TagCreate},
    remove: {right: ELLIS_RIGHTS.TAG_DELETE, action: Delete},
  }, {
    key: 'tanResource',
    name: 'tans',
    options: {label: 'resources.tans.name'},
    icon: TanIcon,
    list: {right: ELLIS_RIGHTS.TAN_LIST, action: TanList},
    create: {right: ELLIS_RIGHTS.TAN_CREATE, action: TanCreate},
  }, {
    key: 'languagesResource',
    name: 'lms/languages',
    options: {label: 'resources.languages.name'},
    icon: LanguageIcon,
    list: {right: true, action: LanguageList},
    edit: {right: true, action: LanguageEdit},
    create: {right: true, action: LanguageCreate},
  }, {
    key: 'configsResource',
    name: 'lms/configs',
    options: {label: 'resources.configs.name'},
    icon: ConfigIcon,
    list: {right: true, action: ConfigList},
    edit: {right: true, action: ConfigEdit},
  }, {
    key: 'passwordChangeResource',
    name: 'password-change',
    options: {label: 'resources.passwordChange.name'},
    icon: PasswordChangeIcon,
    edit: {action: PasswordChangeEdit},
  },
];

function getAction(permissions, {right, action} = {}) {
  if (!action) {
    return null;
  }
  if (right === undefined) {
    return action;
  }
  if (right === true) {
    return permissions.isAdmin() ? action : null;
  }
  return permissions.hasRight(right) ? action : null;
}

/**
 * Main Function that starts the App
 * */
class App extends React.Component {
  /** +++++++++++
   *
   * Method
   * @returns {xml}
   */
  render() {
    return (
      <Admin
        appLayout={Layout}
        theme={getMuiTheme(ellisTheme)}
        menu={Menu}
        title={system.getName()}
        history={history}
        messages={messages}
        dashboard={Dashboard}
        loginPage={LoginPage}
        authClient={authClient}
        restClient={restClient}
        locale={getSelectedLanguageCode()}
        customSagas={customSagas}
        customRoutes={customRoutes}
      >
        {permissions => {
          return RESOURCES.reduce((resources, {key, name, options, icon, show, list, edit, create, remove}) => {
            resources.push(
              <Resource key={key}
                name={name}
                options={options}
                icon={icon}
                list={getAction(permissions, list)}
                show={getAction(permissions, show)}
                create={getAction(permissions, create)}
                edit={getAction(permissions, edit)}
                remove={getAction(permissions, remove)}
              />,
            );
            return resources;
          }, []);
        }}
      </Admin>
    );
  }
}

export default App;
