/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 27.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

/**
 * Reads a file and returns it as base64 string
 * @param {File} file
 * @return {Promise.<string>}
 */
function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = error => {
        reject(error);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      reject(error);
    }
  });
}

export default {convertFileToBase64};
