/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/13/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

import React from 'react';
import * as adminOnRest from 'admin-on-rest';
import participantIcon from 'material-ui/svg-icons/action/assignment';
import schema from './schema';
import formGenerator from '../../forms/form-generator';
import LanguageObjectField from '../../mui/fields/language-object-field';
import ELLIS_RIGHTS from 'ellis-rights';

const {
  Show, SimpleShowLayout, Edit, Create, SimpleForm, EditButton,
  ReferenceField, ReferenceArrayField, SingleFieldList, ChipField,
} = adminOnRest;

export const ParticipantsIcon = participantIcon;

export const ParticipantsShow = (props) => {
  return (
    <Show title="Show group" {...props}>
      {permissions => {
        const fiealds = [
          <ReferenceField
            key="field.training"
            label="resources.participants.fields.training"
            source="training"
            reference="lms/trainings">
            <LanguageObjectField source="name"/>
          </ReferenceField>,
          <ReferenceArrayField
            key="field.users"
            label="resources.participants.fields.users"
            reference="users"
            source="users">
            <SingleFieldList>
              <ChipField source="label"/>
            </SingleFieldList>
          </ReferenceArrayField>,
        ];
        if (permissions.hasRight(ELLIS_RIGHTS.PARTICIPANTS_UPDATE)) {
          fiealds.push(<EditButton key="col.button.edit" basePath="lms/participants"/>);
        }
        return <SimpleShowLayout>{fiealds}</SimpleShowLayout>;
      }}
    </Show>
  );
};

export const ParticipantsEdit = (props) => (
  <Edit title="Edit Participants" {...props}>
    <SimpleForm>
      {formGenerator.getInputFieldsBySchema(schema)}
    </SimpleForm>
  </Edit>
);


export const ParticipantsCreate = (props) => {
  return (
    <Create title="Create a Participants" {...props}>
      <SimpleForm>
        {formGenerator.getInputFieldsBySchema(schema)}
      </SimpleForm>
    </Create>
  );
};
