/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 10.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

/**
 * Types of fields
 * @readonly
 * @enum {string}
 * @name FIELD_TYPES
 */
const FIELD_TYPES = {
  TEXT_FIELD: 'TEXT_FIELD',
  EMAIL_FIELD: 'EMAIL_FIELD',
  URL_FIELD: 'URL_FIELD',
  PASSWORD_FIELD: 'PASSWORD_FIELD',
  TEXT_AREA: 'TEXT_AREA',
  RICH_TEXT: 'RICH_TEXT',
  NUMBER: 'NUMBER',
  RADIO_BUTTONS: 'RADIO_BUTTONS',
  BOOLEAN: 'BOOLEAN',
  SELECT: 'SELECT',
  SELECT_MULTI: 'SELECT_MULTI',
  REFERENCE_SELECT: 'REFERENCE_SELECT',
  REFERENCE_ARRAY_SELECT: 'REFERENCE_ARRAY_SELECT',
  LANGUAGE_FIELD: 'LANGUAGE_FIELD',
  LANGUAGE_FIELD_LONG: 'LANGUAGE_FIELD_LONG',
  DATE_PICKER: 'DATE_PICKER',
  DISABLED_FIELD: 'DISABLED_FIELD',
};

export default FIELD_TYPES;
