/**
 * Created by Markus Faust <markus.faust@visart.eu> on 8/31/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

/* eslint quote-props:off */

const ellisMessagesEN = {
  ellis: {
    forms: {
      login: {
        passwordForgotten: 'Password forgotten?',
      },
      passwordForgotten: {
        mailAddress: 'Mail address',
        submit: 'Request password reset',
        back: 'Back to login',
        actions: {
          requestPasswordReset: {
            start: 'Requesting password reset ...',
            success: '... reset mail sent to provided mail address',
            failure: '... mail could not have been found',
          },
        },
      },
      passwordSet: {
        newPassword: 'New password',
        confirmedPassword: 'Confirm password',
        submit: 'Set password',
        back: 'Back to login',
        actions: {
          requestPasswordSet: {
            start: 'Setting password ...',
            success: '... new password set',
            failure: '... could not set password',
          },
        },
        errors: {
          notMatch: 'Passwords do not match',
        },
      },
      errorMsg: {
        tooFewCharacters: 'Please enter at least %{minLength} characters.',
        tooManyCharacters: 'Please enter no more than %{maxLength} characters.',
        intTooSmall: 'Please choose a number higher than %{minValue}.',
        intTooBig: 'Please enter a number lower than %{maxValue}.',
        notAnEmail: 'Please enter a valid E-Mail Address.',
        notALanguageCode: 'Code must be a 2 or 5 letter language code (ISA 639-1 / ISO 3166)',
        notANonEmptyString: 'Given string must not be empty',
        notValidJson: 'Must be a valid json string',
      },
    },
  },
  resources: {
    users: {
      name: 'User |||| Users',
      fields: {
        username: 'User Name',
        staffNumber: 'Staff Number',
        department: 'Department',
        updateDate: 'Update Date',
        id: 'ID',
        password: 'Password',
        firstName: 'First Name',
        middleName: 'Middle Name',
        lastName: 'Last Name',
        title: 'Title',
        tags: 'Tags',
        address: 'Address',
        zip: 'Postal Code',
        city: 'City',
        state: 'State',
        country: 'Country',
        landLinePhone: 'Land Line Phone',
        mobilePhone: 'Mobile Phone',
        fax: 'Fax',
        www: 'Website',
        businessEmail: 'Business E-Mail',
        privateEmail: 'Private E-Mail',
        company: 'Company',
        position: 'Position',
        dateOfBirth: 'Date of Birth',
        gender: 'Gender',
        language: 'Language',
        acceptedTermsAndConditions: 'User accepts Terms and Conditions',
        selfRegistered: 'Self Registered',
        isAdmin: 'Is Administrator',
        costCenter: 'Cost center',
        isCustomerAdmin: 'Is Customer Administrator',
      },
      filter: {
        username: 'Username',
        staffNumber: 'Staff Number',
        firstName: 'First Name',
        middleName: 'Middle Name',
        lastName: 'Last Name',
        businessEmail: 'Business E-Mail',
        privateEmail: 'Private E-Mail',
      },
      buttons: {
        usersImport: 'Import users',
        usersExport: 'Export users',
        resetPassword: 'Reset Password',
      },
      actions: {
        resetPassword: {
          start: 'Attempting to reset Password ...',
          success: '... Password has been reset. A mail will be sent to the User.',
          failure: '... ERROR, failed to reset password!',
        },
        usersExport: {
          start: 'Exporting users ...',
          success: '... export successful',
          failure: '... failed to export users',
        },
      },
    },
    trainings: {
      name: 'Training |||| Trainings',
      fields: {
        id: 'ID',
        key: 'Unique key',
        name: 'Name',
        description: 'Description',
        startDate: 'Start Date',
        endDate: 'End Date',
        durationInDays: 'Duration (days)',
        requiredTrainings: 'Prerequisite Trainings',
        trainingsEndOptionContent: 'Access to content after completion',
        trainingsEndOptionTest: 'Access to test after completion',
        testEvaluationOption: 'Test Evaluation',
        backupEmail: 'Backup E-Mail',
        trainers: 'Instructor/Trainer',
        department: 'Department',
        invitationMailSubject: 'Invitation E-Mail Subject',
        invitationMailContent: 'Invitation E-Mail Text',
        reminders: 'Reminders',
        certificateMailCC: 'Certificate E-Mail CC',
        certificateMailSubject: 'Certificate E-Mail Subject',
        certificateMailContent: 'Certificate E-Mail Text',
        certificatePdfContent: 'Certification Pdf Text',
        certificateOrientation: 'Orientation of Certificate',
        certificateSignUrl: 'Certificate Sign Url',
        content: 'Content',
        tests: 'Tests',
        layout: 'Mail-Layout (overwrites default)',
        invitationMailTemplate: 'Invitation-Mail-Template (overwrites default)',
        reminderMailTemplate: 'Reminder-Mail-Template (overwrites default)',
        certificateMailTemplate: 'Certificate-Mail-Template (overwrites default)',
        certificatePdfTemplate: 'Certificate-Template (overwrites default)',
      },
      buttons: {
        participants: 'Participants',
        participations: 'Participations',
        resetPassword: 'Reset Password',
      },
    },
    languages: {
      name: 'Language |||| Languages',
      fields: {
        code: 'Code (ISO 639-1 / ISO 3166)',
        name: 'Name',
        default: 'Default',
      },
    },
    content: {
      name: 'Content |||| Content',
      fields: {
        id: 'ID',
        key: 'Unique key',
        name: 'Name',
        typo3groupId: 'TYPO3 GroupId',
      },
    },
    tests: {
      name: 'Test |||| Tests',
      fields: {
        id: 'ID',
        key: 'Unique key',
        name: 'Name',
        attempts: 'Attempts',
        successTarget: 'Success target',
        byQuestions: 'Evaluate by questions',
        typo3groupId: 'TYPO3 GroupId',
        config: 'JSON for Test-Configuration',
      },
    },
    reminders: {
      name: 'Reminder |||| Reminders',
      fields: {
        id: 'ID',
        key: 'Unique key',
        sentAfterDays: 'Send after days',
        mailSubject: 'Mail subject',
        mailText: 'Mail text',
      },
    },
    configs: {
      name: 'Configuration |||| Configurations',
      fields: {
        key: 'Key',
        type: 'Type',
        value: 'Value',
      },
    },
    participants: {
      name: 'Participants |||| Participants',
      fields: {
        training: 'Training',
        users: 'Users',
      },
    },
    participations: {
      name: 'Participation |||| Participations',
      fields: {
        user: 'User',
        startDate: 'Start date',
        endDate: 'End date',
        invitationSent: 'Invitation sent date',
        remindersSent: 'Sent reminders',
        certificateSent: 'Certificate sent date',
        status: 'Status',
      },
      filter: {
        user: 'User',
        status: 'Status',
      },
      buttons: {
        createNewParticipation: 'Create new participation',
        sendInvitation: 'Send invitation',
        sendReminder: 'Send reminder',
        sendCertificate: 'Send certificate',
        participantsExport: 'Export',
      },
      titles: {
        list: 'Participations of training "%{name}"',
      },
      actions: {
        createNewParticipation: {
          start: 'Creating participation ...',
          success: '... participation created',
          failure: '... failed to create participation',
        },
        sendCertificate: {
          start: 'Sending certificate ...',
          success: '... certificate successfully sent',
          failure: '... failed to send certificate',
        },
        sendReminder: {
          start: 'Sending reminder ...',
          success: '... reminder successfully sent',
          failure: '... failed to send reminder',
        },
        sendInvitation: {
          start: 'Sending invitation ...',
          success: '... invitation successfully sent',
          failure: '... failed to send invitation',
        },
        participantsExport: {
          start: 'Exporting ...',
          success: '... export successful',
          failure: '... failed to export',
        },
      },
    },
    passwordChange: {
      name: 'Change Password',
      fields: {
        oldPassword: 'Old password',
        newPassword: 'New password',
        confirmedPassword: 'New password confirmation',
      },
    },
    tags: {
      name: 'Tag |||| Tags',
      fields: {
        value: 'Value',
        isProperty: 'Is property',
      },
      filter: {
        value: 'Value',
      },
      buttons: {
        showPropertyTags: 'Show property TAGs',
      },
    },
    'property-tags': {
      name: 'Property-Tag |||| Property-Tags',
      fields: {
        value: 'Value',
        isProperty: 'Is property',
      },
      filter: {
        value: 'Value',
      },
      buttons: {
        showPropertyTags: 'Show property TAGs',
      },
    },
    groups: {
      name: 'Group |||| Groups',
      fields: {
        key: 'Key',
        name: 'Name',
        parent: 'Parent',
        tags: 'Tags',
        rights: 'Rights',
        supervisors: 'Supervisors',
        adminGroup: 'Only visible for admin',
      },
    },
    tans: {
      name: 'Tan |||| Tans',
      fields: {
        tan: 'Tan',
        reserved: 'Reserved',
        user: 'User',
        amount: 'Amount',
      },
    },
    usersImport: {
      name: 'User import',
      fields: {
        name: 'Name',
        file: 'CSV or XLSX file',
        creationDate: 'Creation date',
        executionStart: 'Execution started at',
        executionFinish: 'Execution finished at',
        entries: 'Entries',
        entry: {
          imported: 'Imported',
          trainings: 'Trainings',
          userExists: 'Exists',
          needsUpdate: 'Changes',
          foundVia: 'Found via',
        },
      },
      show: {
        title: 'User import preview',
        buttons: {
          execute: 'Execute import',
        },
        actions: {
          execute: {
            start: 'Importing users ...',
            success: '... users successfully  imported',
            failure: '... failed to import all users',
          },
        },
      },
    },
    importEntry: {
      name: 'User Import Entry',
      fields: {
        import: 'Import',
        firstName: 'First name',
        lastName: 'Last name',
        foundFirstName: 'Found first name',
        foundLastName: 'Found last name',
        foundVia: 'Found via',
        tags: 'TAGs',
        trainings: 'Trainings',
        error: 'Error',
        done: 'Done at',
      },
    },
    importTagEntry: {
      name: 'User Import Tag Entry',
      fields: {
        import: 'Import',
        tagId: 'ID',
        tagValue: 'Value',
        tagIsProperty: 'Is Property-TAG',
        error: 'Error',
        done: 'Done at',
      },
    },
    importTrainingEntry: {
      name: 'User Import Training Entry',
      fields: {
        import: 'Import',
        trainingId: 'ID',
        trainingKey: 'Key',
        error: 'Error',
      },
    },
  },
};
export {ellisMessagesEN};
