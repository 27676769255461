/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 23.08.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

/**
 * @typedef {Logger} Log
 */

const logger = require('./logger');
let initialized = false;

/**
 * Creates a log
 * @param {string} name
 * @return {Log}
 */
export function create(name) {
  if (!initialized && typeof window === 'object') {
    init(window);
  }
  return logger.getLog(name);
}

/**
 * Initialize the logger
 * @param {Window} win
 */
export function init(win) {
  logger.init(-1, win);
  initialized = true;
}
