/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 14.02.18.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import MuiAppBar from 'material-ui/AppBar';
import muiThemeable from 'material-ui/styles/muiThemeable';
import compose from 'recompose/compose';
import {toggleSidebar as toggleSidebarAction} from 'admin-on-rest';
import LanguageChangeSection from '../section/language-change-section';
import {ellisTextLight} from '../../styles/ellis-colors';

const rightSideIcons = (
  <LanguageChangeSection textColor={ellisTextLight}/>
);

const AppBar = ({title, toggleSidebar, showMenuButton}) => (
  <MuiAppBar
    title={title}
    onLeftIconButtonClick={toggleSidebar}
    iconElementRight={rightSideIcons}
    showMenuIconButton={showMenuButton}
  />
);

AppBar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  showMenuButton: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

const enhance = compose(
  muiThemeable(), // force redraw on theme change
  connect(null, {
    toggleSidebar: toggleSidebarAction,
  }),
);

export default enhance(AppBar);
