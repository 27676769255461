/**
 * Created by René Simon <rene.simon@huchler.it> on 2019-10-03.
 * Copyright © Dr. Huchler und Partner GbR 2019 - present
 */

import axios from 'axios';
import system from '../libs/utils/system';

const AUTH_URL = system.getApiUrl();

const axiosClient = axios.create({
  baseURL: AUTH_URL,
  withCredentials: true,
  auth: {
    username: process.env.REACT_APP_API_USERNAME,
    password: process.env.REACT_APP_API_PASSWORD,
  },
  responseType: 'json',
  responseEncoding: 'utf8',
  timeout: 10000,
});

export async function plainRequest({method, url, body, query, headers}) {
  try {
    const {data} = await axiosClient({
      method,
      url,
      headers,
      params: query,
      data: body,
    });
    return data;
  } catch (error) {
    if (error.response) {
      const {
        status,
        data: {
          error: responseErrorMessage = 'Unknown server response',
          additionInformation,
        } = {},
      } = error.response;
      if (!status) {
        throw new Error(`Did not receive http code from server: ${responseErrorMessage}`);
      }
      let errorMessage = responseErrorMessage;
      if (additionInformation) {
        additionInformation.forEach(information => {
          errorMessage += `\n- property: "${information.property}" reason: "${information.type}"`;
        });
      }
      const e = new Error(errorMessage);
      e.status = status;
      throw e;
    } else if (error.request) {
      throw new Error(`No server response: ${error.message}`);
    } else {
      throw new Error(`Failed to execute request: ${error.message}`);
    }
  }
}
