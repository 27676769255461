/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 05.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import CONFIG_TYPES from '../../consts/config-types';
import LanguageObjectInput from './language-object-input';
import * as aor from 'admin-on-rest';
import {Field} from 'redux-form';

const {LongTextInput, BooleanInput} = aor;

const log = require('../../libs/utils/log').create('dynamicInput');

/**
 * Generates the input field for the config value
 */
class DynamicInput extends Component {
  /**
   * Standard render function
   * @return {xml}
   */
  render() {
    log.info('Dynamic input', this);
    const {props} = this;
    const {record, typeSource, source} = props;
    const type = record[typeSource];
    if (!type) {
      return <div>Could not determine type</div>;
    }
    if (type === CONFIG_TYPES.LANGUAGE_OBJECT) {
      return <LanguageObjectInput long={true} {...props} />;
    }
    if (type === CONFIG_TYPES.STRING) {
      return <Field key={`field-${record.id}`} name={source} component={LongTextInput} type="string"/>;
    }
    if (type === CONFIG_TYPES.BOOLEAN) {
      log.info('Dyn. Boolean', {source, record});
      return <Field key={`field-${record.id}`} name={source} component={BooleanInput} />;
    }
    return <div>{`Unsupported type "${type}"`}</div>;
  }
}

DynamicInput.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  typeSource: PropTypes.string.isRequired,
};

export default connect((state) => {
  return {state: state};
})(DynamicInput);
