/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/12/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

const globalMargin = 16;

const ellisFontStyles = {
  h1: {fontSize: 64},
  h2: {fontSize: 32},
  h3: {fontSize: 28},
};


const ellisLayoutStyles = {
  clear: {clear: 'both'},
  marged: {margin: globalMargin},
  hMarged: {marginLeft: globalMargin, marginRight: globalMargin},
  hDblMarged: {marginLeft: (globalMargin * 2), marginRight: (globalMargin * 2)},
  vMarged: {marginTop: globalMargin, marginBottom: globalMargin},
  vDblMarged: {marginTop: (globalMargin * 2), marginBottom: (globalMargin * 2)},
  bottomMarged: {marginBottom: 32},
  bottomDblMarged: {marginBottom: globalMargin},
  hPadded: {paddingLeft: globalMargin, paddingRight: globalMargin},

};


export {ellisFontStyles, ellisLayoutStyles};
