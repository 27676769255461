/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 22.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

/**
 * @typedef {ReduxAction} TriggerAction
 * @property {string} notifications
 * @property {string} route
 * @property {string} source
 * @property {Object} record
 * @property {Error} [error]
 */

import {all, call, put, takeEvery} from 'redux-saga/effects';
import {refreshView, showNotification} from 'admin-on-rest';
import {TRIGGER_ACTION} from './trigger-action';
import actionNotification from '../../libs/utils/action-notification';
import restClient from '../../libs/rest-client';

const TRIGGER_ACTION_SUCCESS = 'TRIGGER_ACTION_SUCCESS';
const TRIGGER_ACTION_FAILURE = 'TRIGGER_ACTION_FAILURE';

const log = require('../../libs/utils/log').create('TriggerSage');

/**
 * Creating an action
 * @param {string} type
 * @param {TriggerAction} action
 * @param {Error} [error]
 * @return {TriggerAction}
 */
function createAction(type, action, error) {
  const {notifications, route, source, record, refreshAfter} = action;
  const newAction = {
    type,
    notifications,
    route,
    source,
    record,
    refreshAfter,
  };
  if (error) {
    newAction.error = error;
  }
  return newAction;
}

/**
 * Trigger action
 * @param {TriggerAction} action
 */
function* executeAction(action) {
  try {
    log.info('Received action', action);
    const {notifications, route, source, source2, record} = action;
    yield put(showNotification(actionNotification(notifications).START));
    const value = getSourceFromRecord(record, source);
    const value2 = getSourceFromRecord(record, source2);
    const url = route
      .replace('#{value}', value)
      .replace('#{value2}', value2);
    const response = yield call(() => {
      return restClient('CREATE', url, {data: {}});
    });
    log.info('Received action response', {url, response});
    yield put(createAction(TRIGGER_ACTION_SUCCESS, action));
  } catch (error) {
    yield put(createAction(TRIGGER_ACTION_FAILURE, action, error));
  }
}

function getSourceFromRecord(record, source) {
  if (!record) {
    return undefined;
  }
  if (!source) {
    return undefined;
  }
  if (typeof source !== 'string') {
    throw new Error(`Invalid source provided`);
  }
  if (!Object.prototype.hasOwnProperty.call(record, source)) {
    return undefined;
  }
  const value = record[source];
  if (!value) {
    return value;
  }
  return value.id || value._id || value;
}

/**
 * Handles trigger success
 * @param {TriggerAction} action
 */
function* triggerSuccess(action) {
  const {notifications, refreshAfter} = action;
  yield put(showNotification(actionNotification(notifications).SUCCESS));
  if (refreshAfter) {
    yield put(refreshView());
  }
}

/**
 * Handle trigger failure
 * @param {TriggerAction} action
 */
function* triggerFailure(action) {
  const {notifications, error, refreshAfter} = action;
  log.warn('Failed to trigger action', error);
  yield put(showNotification(actionNotification(notifications).FAILURE, 'warning'));
  if (refreshAfter) {
    yield put(refreshView());
  }
}

/**
 * Sage for the trigger action
 */
export default function* triggerSaga() {
  yield all([
    takeEvery(TRIGGER_ACTION, executeAction),
    takeEvery(TRIGGER_ACTION_SUCCESS, triggerSuccess),
    takeEvery(TRIGGER_ACTION_FAILURE, triggerFailure),
  ]);
}
