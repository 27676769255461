/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 16.05.18.
 * Copyright © Dr. Huchler und Partner 2017
 */

import ELLIS_RIGHTS from 'ellis-rights';
import FIELD_TYPES from '../../consts/field-types';
import {minStringLength, validNonEmptyString} from '../../forms/form-utils/field-validators';
import mongoIdsParser from '../../redux/parser/mongo-ids-parser';
import {getCurrentUser} from '../../libs/utils/current-user';

const RIGHT_OPTIONS = ELLIS_RIGHTS.getSelectOptions();

export default {
  i18nPath: 'resources.groups.fields.',
  fields: {
    key: {
      type: FIELD_TYPES.TEXT_FIELD,
      validatorFunctions: [validNonEmptyString, minStringLength(1)],
    },
    name: {
      type: FIELD_TYPES.LANGUAGE_FIELD,
    },
    parent: {
      type: FIELD_TYPES.REFERENCE_SELECT,
      resource: 'groups',
      source: 'id',
      optionLabelSource: 'key',
      filter: {supervised: true},
      allowEmpty: () => getCurrentUser('isAdmin'),
    },
    tags: {
      type: FIELD_TYPES.REFERENCE_ARRAY_SELECT,
      resource: 'tags',
      source: 'id',
      optionLabelSource: 'value',
      parser: mongoIdsParser,
    },
    rights: {
      type: FIELD_TYPES.SELECT_MULTI,
      optionsList: () => {
        if (getCurrentUser('isAdmin')) {
          return Object.keys(RIGHT_OPTIONS).map(label => ([label, RIGHT_OPTIONS[label]]));
        }
        const rights = getCurrentUser('rights');
        return rights.map(right => {
          const label = ELLIS_RIGHTS.getRight(right).name;
          return [
            label,
            RIGHT_OPTIONS[label],
          ];
        });
      },
    },
    supervisors: {
      type: FIELD_TYPES.REFERENCE_ARRAY_SELECT,
      resource: 'users',
      source: 'id',
      optionLabelSource: 'label',
      parser: mongoIdsParser,
    },
    adminGroup: {
      disabled: () => !getCurrentUser('isAdmin'),
      type: FIELD_TYPES.BOOLEAN,
    },
  },
};
