/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 18.11.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

const Cookie = require('js-cookie');

export default {
  set: (key, value) => {
    Cookie.set(key, value);
  },
  get: key => {
    return Cookie.get(key);
  },
};
