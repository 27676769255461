/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 16.05.18.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React from 'react';
import * as adminOnRest from 'admin-on-rest';
import icon from 'material-ui/svg-icons/communication/vpn-key';
import {minIntValue} from '../../forms/form-utils/field-validators';

const {List, Datagrid, Create, SimpleForm, BooleanField, TextField, NumberInput, ReferenceField} = adminOnRest;

export const TanIcon = icon;

export const TanList = (props) => {
  return (
    <List title="All Tans" {...props}>
      <Datagrid>
        <TextField source="tan"/>
        <BooleanField source="reserved"/>
        <ReferenceField
          label="resources.tans.fields.user"
          source="user"
          reference="users">
          <TextField source="label"/>
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
const validate = [number, minIntValue(1)];

export const TanCreate = (props) => {
  return (
    <Create title="Create a Tan" {...props}>
      <SimpleForm redirect="list">
        <NumberInput
          label="resources.tans.fields.amount"
          source="amount"
          key="amount"
          defaultValue={1}
          validate={validate}
          options={{fullWidth: true}}
        />
      </SimpleForm>
    </Create>
  );
};
