/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 16.05.18.
 * Copyright © Dr. Huchler und Partner 2017
 */

import LanguageObjectField from '../../mui/fields/language-object-field';
import React from 'react';
import * as adminOnRest from 'admin-on-rest';
import icon from 'material-ui/svg-icons/action/account-circle';
import schema from './schema';
import formGenerator from '../../forms/form-generator';
import ELLIS_RIGHTS from 'ellis-rights';

const {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  EditButton,
  TextField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Show,
  SimpleShowLayout,
  ShowButton,
} = adminOnRest;

export const GroupIcon = icon;

export const GroupList = (props) => {
  return (
    <List title="All Groups" {...props}>
      {permissions => {
        const COLUMNS = [
          <TextField key="col.username" source="key"/>,
          <LanguageObjectField key="col.username" source="name"/>,
          <ReferenceField
            key="col.parent"
            label="resources.groups.fields.parent"
            source="parent"
            reference="groups">
            <LanguageObjectField source="name"/>
          </ReferenceField>,
          <ReferenceArrayField
            key="col.users"
            label="resources.groups.fields.supervisors"
            reference="users"
            source="supervisors">
            <SingleFieldList>
              <ChipField source="label"/>
            </SingleFieldList>
          </ReferenceArrayField>,
        ];
        if (permissions.hasRight(ELLIS_RIGHTS.GROUP_SHOW)) {
          COLUMNS.push(<ShowButton key="col.button.show"/>);
        }
        if (permissions.hasRight(ELLIS_RIGHTS.GROUP_UPDATE)) {
          COLUMNS.push(<EditButton key="col.button.edit"/>);
        }
        return <Datagrid>{COLUMNS}</Datagrid>;
      }}
    </List>
  );
};

export const GroupShow = (props) => {
  return (
    <Show title="Show group" {...props}>
      <SimpleShowLayout>
        <LanguageObjectField source="name"/>
        <ReferenceField
          label="resources.groups.fields.parent"
          source="parent"
          reference="groups">
          <LanguageObjectField source="name"/>
        </ReferenceField>
        <ReferenceArrayField label="resources.groups.fields.supervisors" reference="users" source="supervisors">
          <SingleFieldList>
            <ChipField source="label"/>
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField label="resources.groups.fields.tags" reference="tags" source="tags">
          <SingleFieldList>
            <ChipField source="value"/>
          </SingleFieldList>
        </ReferenceArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export const GroupEdit = (props) => (
  <Edit title="Edit Group" {...props}>
    <SimpleForm>
      {formGenerator.getInputFieldsBySchema(schema)}
    </SimpleForm>
  </Edit>
);


export const GroupCreate = (props) => {
  return (
    <Create title="Create a Group" {...props}>
      <SimpleForm>
        {formGenerator.getInputFieldsBySchema(schema)}
      </SimpleForm>
    </Create>
  );
};
