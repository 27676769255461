/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/12/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

import {
  ellisAlertGreen,
  ellisAlertPink,
  ellisAlertYellow,
  ellisBckGrndWhite,
  ellisLightBlue,
  ellisMidGrey,
  ellisPaynesGrey,
  ellisTextDark,
  ellisTextLight,
} from './ellis-colors';
import {fade} from 'material-ui/utils/colorManipulator';


const ellisTheme = {
  palette: {
    primary1Color: ellisLightBlue,
    primary2Color: ellisPaynesGrey,
    primary3Color: ellisLightBlue,
    accent1Color: ellisAlertPink,
    accent2Color: ellisAlertYellow,
    accent3Color: ellisAlertGreen,
    textColor: ellisTextDark,
    alternateTextColor: ellisTextLight,
    canvasColor: ellisBckGrndWhite,
    borderColor: ellisAlertYellow,
    disabledColor: fade(ellisTextDark, 0.3),
    pickerHeaderColor: ellisMidGrey,
    clockCircleColor: fade(ellisTextDark, 0.07),
    shadowColor: ellisAlertYellow,
  },
  appBar: {
    height: 50,
  },
  button: {
    iconButtonSize: 16,
  },
  dropDownMenu: {
    accentColor: ellisPaynesGrey,
  },
  raisedButton: {
    color: ellisLightBlue,
    textColor: ellisTextLight,
    primaryColor: ellisAlertPink,
    primaryTextColor: ellisTextLight,
    secondaryColor: ellisAlertGreen,
    secondaryTextColor: ellisTextLight,
    disabledColor: fade(ellisMidGrey, 0.3),
    disabledTextColor: fade(ellisPaynesGrey, 0.3),
    fontSize: 18,
    fontWeight: 300,
  },
};

export {ellisTheme};
