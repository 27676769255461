/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on ${DATE}.
 * Copyright © Dr. Huchler & Partner 2017
 */

import React from 'react';
import {titleCase} from 'change-case';
import * as adminOnRest from 'admin-on-rest';
import RichTextInput from 'aor-rich-text-input';
import LanguageObjectInput from '../mui/inputs/language-object-input';
import FIELD_TYPES from '../consts/field-types';

const {
  BooleanInput, SelectInput, TextInput, LongTextInput, DateInput, NumberInput, ReferenceInput,
  RadioButtonGroupInput, ReferenceArrayInput, CheckboxGroupInput, SelectArrayInput, DisabledInput,
} = adminOnRest;
const log = require('../libs/utils/log').create('trainingView');

const FIELD_RENDERERS = {
  [FIELD_TYPES.TEXT_FIELD]: (name, field, labelPath) => {
    return getStandardField(name, field, labelPath, TextInput, 'text');
  },
  [FIELD_TYPES.DISABLED_FIELD]: (name, field, labelPath) => {
    return getStandardField(name, field, labelPath, DisabledInput, 'text');
  },
  [FIELD_TYPES.EMAIL_FIELD]: (name, field, labelPath) => {
    return getStandardField(name, field, labelPath, TextInput, 'email');
  },
  [FIELD_TYPES.URL_FIELD]: (name, field, labelPath) => {
    return getStandardField(name, field, labelPath, TextInput, 'url');
  },
  [FIELD_TYPES.PASSWORD_FIELD]: (name, field, labelPath) => {
    return getStandardField(name, field, labelPath, TextInput, 'password');
  },
  [FIELD_TYPES.TEXT_AREA]: (name, field, labelPath) => {
    return getStandardField(name, field, labelPath, LongTextInput);
  },
  [FIELD_TYPES.RICH_TEXT]: (name, field, labelPath) => {
    return getStandardField(name, field, labelPath, RichTextInput);
  },
  [FIELD_TYPES.NUMBER]: (name, field, labelPath) => {
    return getStandardField(name, field, labelPath, NumberInput);
  },
  [FIELD_TYPES.BOOLEAN]: (name, field, labelPath) => {
    return getStandardField(name, field, labelPath, BooleanInput, undefined, false);
  },
  [FIELD_TYPES.DATE_PICKER]: (name, field, labelPath) => {
    return getStandardField(name, field, labelPath, DateInput);
  },
  [FIELD_TYPES.RADIO_BUTTONS]: (name, field, labelPath) => {
    return getOptionsField(name, field, labelPath, RadioButtonGroupInput);
  },
  [FIELD_TYPES.SELECT]: (name, field, labelPath) => {
    return getOptionsField(name, field, labelPath, SelectInput);
  },
  [FIELD_TYPES.SELECT_MULTI]: (name, field, labelPath) => {
    return getOptionsField(name, field, labelPath, SelectArrayInput);
  },
  [FIELD_TYPES.REFERENCE_SELECT]: (name, field, labelPath) => {
    return getReferenceField(name, field, labelPath);
  },
  [FIELD_TYPES.REFERENCE_ARRAY_SELECT]: (name, field, labelPath) => {
    return getReferenceField(name, field, labelPath, true);
  },
  [FIELD_TYPES.LANGUAGE_FIELD]: (name, field, labelPath) => {
    return getLanguageObjectField(name, labelPath, false);
  },
  [FIELD_TYPES.LANGUAGE_FIELD_LONG]: (name, field, labelPath) => {
    return getLanguageObjectField(name, labelPath, true);
  },
};

/**
 * Generates a standard field
 * @param {string} name
 * @param {SchemaField} field
 * @param {string} labelPath
 * @param {Object} Component
 * @param {string} type
 * @param {boolean} fullWidth
 * @return {xml}
 */
function getStandardField(name, field, labelPath, Component, type, fullWidth = true) {
  const options = {};
  if (fullWidth) {
    options.fullWidth = true;
  }
  const additionalFields = {};
  if (type === 'password') {
    additionalFields. autocomplete = 'new-password';
  }
  return <Component
    label={labelPath + name}
    source={name}
    key={name}
    type={type}
    validate={field.validatorFunctions}
    onChange={field.onChange}
    options={options}
    {...additionalFields}
  />;
}

/**
 * Generates a options field
 * @param {string} name
 * @param {SchemaField} field
 * @param {string} labelPath
 * @param {Object} Component
 * @return {xml}
 */
function getOptionsField(name, field, labelPath, Component) {
  const {optionsList} = field;
  const options = typeof optionsList === 'function' ? optionsList() : optionsList;
  const choices = optionsToChoices(options);
  return <Component
    label={labelPath + name}
    source={name}
    key={name}
    choices={choices}
  />;
}

/**
 * Returns choices for the given options
 * @param {Array.<({id: string, name: string} | Array.<string> | string)>} options
 * @return {Array.<{id: string, name: string}>}
 */
function optionsToChoices(options) {
  return options.map(value => {
    if (Array.isArray(value)) {
      return {id: value[1], name: titleCase(value[0])};
    }
    if (typeof value !== 'object') {
      value = {
        id: value,
        name: value,
      };
    }
    return {
      id: value.id,
      name: titleCase(value.name),
    };
  });
}

/**
 * Returns a renderer
 * @param {string} labelPath
 * @return {function(*=, *=)}
 */
function getRenderer(labelPath) {
  /**
   * Creates a field of the given specified type
   * @param {string} name
   * @param {SchemaField} field
   * @param {boolean} edit
   * @return {xml}
   */
  return (name, field, edit) => {
    let {type, editType} = field;
    if (edit && editType) {
      type = editType;
    }
    const renderer = FIELD_RENDERERS[type];
    if (!renderer) {
      log.error(`Unknown formFieldType for ${name}, type: ${type}`);
      throw new Error(`There is no renderer for type "${type}"`);
    }
    return renderer(name, field, labelPath);
  };
}

/**
 * Generates a reverence field
 * @param {string} name
 * @param {SchemaField} field
 * @param {string} labelPath
 * @param {boolean} [multi=false]
 * @return {xml}
 */
function getReferenceField(name, field, labelPath, multi = false) {
  if (!validateReferenceField(field)) {
    log.error(`ReferenceInput Field for ${name} is missing Resource, Source and/or Option Label Source definition`);
    return <p>Miss-configured Reference Field</p>;
  }
  let {parser, useCheckboxes} = field;
  const RefInput = multi ? ReferenceArrayInput : ReferenceInput;
  const SelInput = multi ? useCheckboxes ? CheckboxGroupInput : SelectArrayInput : SelectInput;
  if (!parser) {
    parser = v => v;
  }
  return <RefInput
    key={name}
    label={labelPath + name}
    source={name}
    reference={field.resource}
    options={{fullWidth: true}}
    perPage={Number.MAX_SAFE_INTEGER}
    sort={{field: field.optionLabelSource, order: 'ASC'}}
    parse={parser}
    filter={Object.assign({}, field.filter || {}, field.optionFilter ? field.optionFilter() : {})}
    allowEmpty={field.allowEmpty ? field.allowEmpty() : true}>
    <SelInput optionText={field.optionLabelSource} optionValue="id" options={{fullWidth: true}}/>
  </RefInput>;
}

/**
 * Validates if all need properties exist for reference fields
 * @param {SchemaField} field
 * @return {boolean}
 */
function validateReferenceField(field) {
  return !!field.resource && !!field.source && !!field.optionLabelSource;
}

/**
 * Generates a reverence field
 * @param {string} name
 * @param {string} labelPath
 * @param {boolean} long
 * @return {xml}
 */
function getLanguageObjectField(name, labelPath, long) {
  return <LanguageObjectInput addLabel
    label={labelPath + name}
    source={name}
    key={name}
    long={long}
  />;
}

/**
 * Renders a form based on a schema
 * @param {Schema} schema
 * @param {boolean} [edit=false]
 * @return {[XML]}
 */
function getInputFieldsBySchema(schema, edit = false) {
  const labelPath = schema.i18nPath;
  const fields = schema.fields;
  const render = getRenderer(labelPath);
  return Object.keys(fields).filter(name => {
    const disabled = fields[name].disabled;
    if (!disabled) {
      return true;
    }
    if (typeof disabled === 'function') {
      return !disabled();
    }
    return false;
  }).map(name => {
    const field = fields[name];
    return render(name, field, edit);
  });
}

export default {getInputFieldsBySchema, FIELD_TYPES};
