/**
 * Created by René Simon <rene.simon@huchler.it> on 2019-10-03.
 * Copyright © Dr. Huchler und Partner GbR 2019 - present
 */

import {all, put, takeEvery} from 'redux-saga/effects';
import {refreshView, showNotification} from 'admin-on-rest';
import {NOTIFY} from './consts';

const log = require('../../libs/utils/log').create('Notification');

/**
 * Handle trigger failure
 * @param {TriggerAction} action
 */
function* notify({message, refresh, error}) {
  if (error) {
    log.error('Received error', error);
  }
  yield put(showNotification(message, error ? 'warning' : undefined));
  if (refresh) {
    yield put(refreshView());
  }
}

export default function* triggerSaga() {
  yield all([
    takeEvery(NOTIFY, notify),
  ]);
}
