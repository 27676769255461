/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 28.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React, {Component} from 'react';
import {ellisFontStyles, ellisLayoutStyles} from '../../styles/ellis-styles';
import LanguageChangeButton from '../buttons/language-change-button';
import PropTypes from 'prop-types';
import {ellisTextDark} from '../../styles/ellis-colors';

const div1Style = Object.assign({}, ellisLayoutStyles.hMarged, ellisFontStyles.h2, ellisLayoutStyles.clear);


/**
 * Generates a table for the given entries
 */
class LanguageChangeSection extends Component {
  /**
   * Standard render function
   * @return {xml}
   */
  render() {
    const textColor = this.props.textColor || ellisTextDark;
    return <div style={div1Style}>
      <LanguageChangeButton locale="en" textColor={textColor}/>
      <LanguageChangeButton locale="de" textColor={textColor}/>
    </div>;
  }
}

LanguageChangeSection.propTypes = {
  textColor: PropTypes.string,
};

export default LanguageChangeSection;
