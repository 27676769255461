/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 03.10.19.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React from 'react';
import {Route} from 'react-router-dom';
import PasswordForgotten from './views/passwordForgotten';
import PasswordSet from './views/passwordSet';

export const customRoutes = [
  <Route key="cr/passwordForgotten" exact path="/password-forgotten" component={PasswordForgotten} />,
  <Route key="cr/passwordSet" path="/password-set" component={PasswordSet} />,
];
