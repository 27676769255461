/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/13/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

import LanguageObjectField from '../../mui/fields/language-object-field';
import LinkButton from '../../mui/buttons/link-button';
import React from 'react';
import * as adminOnRest from 'admin-on-rest';
import trainingIcon from 'material-ui/svg-icons/action/assignment';
import RemoveRedEyeIcon from 'material-ui/svg-icons/image/remove-red-eye';
import {ParticipantsIcon} from '../participants';
import schema from './schema';
import formGenerator from '../../forms/form-generator';
import ELLIS_RIGHTS from 'ellis-rights';

const {List, Datagrid, Edit, Create, SimpleForm, DateField, EditButton, DisabledInput} = adminOnRest;

export const TrainingIcon = trainingIcon;

export const TrainingList = (props) => {
  return (
    <List title="All Trainings" {...props}>
      {permissions => {
        const COLUMNS = [
          <LanguageObjectField key="col.name" source="name"/>,
          <DateField key="col.startDate" source="startDate"/>,
          <DateField key="col.endDate" source="endDate"/>,
        ];
        if (permissions.hasRight(ELLIS_RIGHTS.TRAINING_CONTENT_UPDATE)) {
          COLUMNS.push(<EditButton key="col.button.edit" basePath="lms/trainings"/>);
        }
        if (permissions.hasRight(ELLIS_RIGHTS.PARTICIPANTS_SHOW)) {
          COLUMNS.push(<LinkButton
            route="/lms/participants/#{value}/show"
            source="id"
            label="resources.trainings.buttons.participants"
            icon={<ParticipantsIcon/>}
          />);
        }
        if (permissions.hasRight(ELLIS_RIGHTS.PARTICIPATION_LIST)) {
          COLUMNS.push(<LinkButton
            route="/lms/participations/#{value}"
            source="id"
            label="resources.trainings.buttons.participations"
            icon={<RemoveRedEyeIcon/>}
          />);
        }
        return <Datagrid>{COLUMNS}</Datagrid>;
      }}
    </List>
  );
};

export const TrainingEdit = (props) => (
  <Edit title="Edit Training" {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      {formGenerator.getInputFieldsBySchema(schema, true)}
    </SimpleForm>
  </Edit>
);


export const TrainingCreate = (props) => {
  return (
    <Create title="Create a Training" {...props}>
      <SimpleForm>
        {formGenerator.getInputFieldsBySchema(schema)}
      </SimpleForm>
    </Create>
  );
};
