/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/8/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

import FIELD_TYPES from '../../consts/field-types';
import mongoIdsParser from '../../redux/parser/mongo-ids-parser';

export default {
  i18nPath: 'resources.participants.fields.',
  fields: {
    training: {
      type: FIELD_TYPES.REFERENCE_SELECT,
      resource: 'lms/trainings',
      source: 'id',
      optionLabelSource: 'key',
    },
    users: {
      type: FIELD_TYPES.REFERENCE_ARRAY_SELECT,
      resource: 'users',
      source: 'id',
      optionLabelSource: 'label',
      parser: mongoIdsParser,
      useCheckboxes: false,
    },
  },
};
