/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 16.05.18.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React from 'react';
import * as adminOnRest from 'admin-on-rest';
import {CardActions} from 'material-ui/Card';
import icon from 'material-ui/svg-icons/action/label';
import schema from './schema';
import formGenerator from '../../forms/form-generator';
import ELLIS_RIGHTS from 'ellis-rights';

const {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  EditButton,
  TextField,
  Filter,
  TextInput,
  CreateButton,
  RefreshButton,
} = adminOnRest;

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

// eslint-disable-next-line react/prop-types
const TagActions = ({resource, filters, displayedFilters, filterValues, basePath}) => {
  return <CardActions style={cardActionStyle}>
    <CreateButton basePath={basePath}/>
    <RefreshButton/>
  </CardActions>;
};

const TagFilter = (props) => (
  <Filter {...props}>
    <TextInput label="resources.tags.filter.value" source="value"/>
  </Filter>
);

export const TagIcon = icon;

export const TagList = (props) => {
  return (
    <List title="All Tags"
      actions={<TagActions />}
      filters={<TagFilter/>}
      filter={{isProperty: false}}
      {...props}
    >
      {permissions => {
        const COLUMNS = [
          <TextField key="col.value" source="value"/>,
        ];
        if (permissions.hasRight(ELLIS_RIGHTS.TRAINING_CONTENT_UPDATE)) {
          COLUMNS.push(<EditButton key="col.button.edit" basePath="tags"/>);
        }
        return <Datagrid>{COLUMNS}</Datagrid>;
      }}
    </List>
  );
};

export const PropertyTagList = (props) => {
  return (
    <List title="All Tags"
      actions={<TagActions />}
      filters={<TagFilter/>}
      filter={{isProperty: true}}
      {...props}
    >
      {permissions => {
        const COLUMNS = [
          <TextField key="col.value" source="value"/>,
        ];
        if (permissions.hasRight(ELLIS_RIGHTS.TRAINING_CONTENT_UPDATE)) {
          COLUMNS.push(<EditButton key="col.button.edit" basePath="tags"/>);
        }
        return <Datagrid>{COLUMNS}</Datagrid>;
      }}
    </List>
  );
};

export const TagEdit = (props) => (
  <Edit title="Edit Tag" {...props}>
    <SimpleForm>
      {formGenerator.getInputFieldsBySchema(schema)}
    </SimpleForm>
  </Edit>
);


export const TagCreate = (props) => {
  return (
    <Create title="Create a Tag" {...props}>
      <SimpleForm>
        {formGenerator.getInputFieldsBySchema(schema)}
      </SimpleForm>
    </Create>
  );
};
