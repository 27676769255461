/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 05.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import TranslatedLabel from '../utils/translated-label';
import {triggerAction} from '../../redux/trigger/trigger-action';

/**
 * Generates the input field for the config value
 */
class TriggerButton extends Component {
  /**
   * Handles the click on the button
   */
  handleClick() {
    const {notifications, record, triggerAction, source, source2, route, refreshAfter} = this.props;
    triggerAction(notifications, route, source, source2, record, refreshAfter);
  }

  /**
   * Standard render function
   * @return {xml}
   */
  render() {
    const props = this.props;
    const {icon, label} = props;
    const onClick = this.handleClick.bind(this);

    return <FlatButton label={<TranslatedLabel label={label}/>}
      icon={icon}
      onClick={onClick}
      style={{overflow: 'inherit'}}/>;
  }
}

TriggerButton.propTypes = {
  triggerAction: PropTypes.func,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  source2: PropTypes.string,
  label: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  notifications: PropTypes.string.isRequired,
  icon: PropTypes.node,
  refreshAfter: PropTypes.bool,
};

export default connect(null, {triggerAction})(TriggerButton);
