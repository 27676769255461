/**
 * Created by Markus Faust <markus.faust@visart.eu> on 8/31/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

/* eslint quote-props:off */

const ellisMessagesDE = {
  aor: {
    action: {
      delete: 'Löschen',
      show: 'Anzeigen',
      list: 'Liste',
      save: 'Speichern',
      create: 'Erstellen',
      edit: 'Bearbeiten',
      cancel: 'Abbrechen',
      refresh: 'Aktualisieren',
      add_filter: 'Filter hinzufügen',
      remove_filter: 'Filter entfernen',
    },
    boolean: {
      true: 'Ja',
      false: 'Nein',
    },
    page: {
      list: 'Liste von %{name}',
      edit: '%{name} #%{id}',
      show: '%{name} #%{id}',
      create: '%{name} erstellen',
      delete: '%{name} #%{id} löschen',
      dashboard: 'Übersicht',
    },
    input: {
      image: {
        upload_several: 'Zum hochladen Dateien hinein ziehen oder hier klicken, um Dateien auszuwählen.',
        upload_single: 'Zum hochladen Datei hinein ziehen oder hier klicken, um eine Datei auszuwählen.',
      },
    },
    message: {
      yes: 'Ja',
      no: 'Nein',
      are_you_sure: 'Bist du sicher?',
      about: 'Über',
    },
    navigation: {
      page_out_of_boundaries: 'Die Seite %{page} liegt außerhalb des gültigen Bereichs',
      page_out_from_end: 'Letzte Seite',
      page_out_from_begin: 'Erste Seite',
      page_range_info: '%{offsetBegin}-%{offsetEnd} von %{total}',
      next: 'Weiter',
      prev: 'Zurück',
      no_results: 'Keine Einträge gefunden',
    },
    auth: {
      username: 'Nutzername',
      password: 'Passwort',
      sign_in: 'Anmelden',
      sign_in_error: 'Fehler bei der Anmeldung',
      logout: 'Abmelden',
    },
    notification: {
      updated: 'Element wurde aktualisiert',
      created: 'Element wurde erstellt',
      deleted: 'Element wurde gelöscht',
      item_doesnt_exist: 'Das Element existiert nicht',
    },
    validation: {
      required: 'Benötigt',
    },
  },
  ellis: {
    forms: {
      login: {
        passwordForgotten: 'Passwort vergessen?',
      },
      passwordForgotten: {
        mailAddress: 'Mailadresse',
        submit: 'Passwort zurücksetzen',
        back: 'Zurück zum Login',
        actions: {
          requestPasswordReset: {
            start: 'Sende Anfrage ...',
            success: '... Mail gesendet an Mailadresse',
            failure: '... Mailadresse konnte nicht gefunden werden',
          },
        },
      },
      passwordSet: {
        newPassword: 'Neues Passwort',
        confirmedPassword: 'Passwort bestätigen',
        submit: 'Passwort setzen',
        back: 'Zurück zum Login',
        actions: {
          requestPasswordSet: {
            start: 'Setze Passwort ...',
            success: '... neues Passwort gesetzt',
            failure: '... Fehler beim Setzen des neuen Passworts',
          },
        },
        errors: {
          notMatch: 'Passwörter stimmen nicht überein',
        },
      },
      errorMsg: {
        tooFewCharacters: 'Bitte geben sie mindestens %{minLength} Zeichen ein.',
        tooManyCharacters: 'Bitte geben sie nicht mehr als %{maxLength} Zeichen ein.',
        intTooSmall: 'Bitte geben sie mindestens %{minValue} ein.',
        intTooBig: 'Bitte geben sie höchstens %{maxValue} ein.',
        notAnEmail: 'Bitte geben sie eine gültige Email Adresse ein.',
        notALanguageCode: 'Muss ein 2 oder 5 Buchstaben Sprachcode sein (ISA 639-1 / ISO 3166)',
        notANonEmptyString: 'Zeichenkette darf nicht leer sein',
        notValidJson: 'Zeichenkette muss ein valid json sein',
      },
    },
  },
  resources: {
    users: {
      name: 'Benutzer |||| Benutzer',
      fields: {
        username: 'Benutzername',
        staffNumber: 'Angestellten Nummer',
        department: 'Abteilung',
        updateDate: 'Aktualisiert am',
        id: 'Kennnummer',
        password: 'Passwort',
        firstName: 'Vorname',
        middleName: 'Zweiter Vorname',
        lastName: 'Nachname',
        title: 'Anrede',
        tags: 'Tags',
        address: 'Adresse',
        zip: 'Postleitzahl',
        city: 'Ort',
        state: 'Bundesland',
        country: 'Land',
        landLinePhone: 'Festanschluss',
        mobilePhone: 'Handynummer',
        fax: 'Faxnummer',
        www: 'Webseite',
        businessEmail: 'Betriebliche Email',
        privateEmail: 'Private Email',
        company: 'Firma',
        position: 'Position',
        dateOfBirth: 'Geburtsdatum',
        gender: 'Geschlecht',
        language: 'Sprache',
        acceptedTermsAndConditions: 'Ich stimme den allgemeine Geschäftsbedingungen zu.',
        selfRegistered: 'Selbst registriert',
        isAdmin: 'Ist Administrator',
        costCenter: 'Kostenstelle',
        isCustomerAdmin: 'Ist Kunden-Administrator',
      },
      filter: {
        username: 'Benutzername',
        staffNumber: 'Angestellten Nummer',
        firstName: 'Vorname',
        middleName: 'Zweiter Vorname',
        lastName: 'Nachname',
        businessEmail: 'Betriebliche Email',
        privateEmail: 'Private Email',
      },
      buttons: {
        usersImport: 'Benutzer importieren',
        usersExport: 'Benutzer exportieren',
        resetPassword: 'Passwort zurücksetzen',
      },
      actions: {
        resetPassword: {
          start: 'Versuche Passwort zuückzusetzen ...',
          success: '... Passwort erfolgreich zuückgesetzt. Eine Mail geht an den Teilnehmer.',
          failure: '... Fehler beim Zurücksetzen des Passwortes',
        },
        usersExport: {
          start: 'Exportiere Benutzer ...',
          success: '... Export erfolgreich',
          failure: '... Export fehlgeschlagen',
        },
      },
    },
    trainings: {
      name: 'Training |||| Trainings',
      fields: {
        id: 'ID',
        key: 'Eindeutiger Schlüssel',
        name: 'Name',
        description: 'Beschreibung',
        startDate: 'Anfangsdatum',
        endDate: 'Enddatum',
        durationInDays: 'Dauer in Tagen',
        requiredTrainings: 'Vorausgesetzte Trainings',
        trainingsEndOptionContent: 'Zugriff auf Inhalt nach Trainingsabschluss',
        trainingsEndOptionTest: 'Zugriff auf Test nach Trainingsabschluss',
        testEvaluationOption: 'Testauswertung',
        backupEmail: 'Backup E-Mail',
        trainers: 'Ausbilder',
        department: 'Abteilung',
        invitationMailSubject: 'Einladungsmail, Betreff',
        invitationMailContent: 'Einladungsmail, Text',
        reminders: 'Erinnerungen',
        certificateMailCC: 'Zertifikatsmail CC',
        certificateMailSubject: 'Zertifikatsmail, Betreff',
        certificateMailContent: 'Zertifikatsmail, Text',
        certificatePdfContent: 'Zertifikatsmail, Pdf Text',
        certificateOrientation: 'Zertifikat Ausrichtung',
        certificateSignUrl: 'Zertifikat Unterschrift Url',
        content: 'Trainings Inhalt',
        tests: 'Tests',
        layout: 'Mail-Layout (überschreibt Standard)',
        invitationMailTemplate: 'Einladungsmail-Template (überschreibt Standard)',
        reminderMailTemplate: 'Erinnerungsmail-Template (überschreibt Standard)',
        certificateMailTemplate: 'Zertifikatsmail-Template (überschreibt Standard)',
        certificatePdfTemplate: 'Zertifikat-PDF-Template (überschreibt Standard)',
      },
      buttons: {
        participations: 'Teilnahmen',
      },
    },
    languages: {
      name: 'Sprache |||| Sprachen',
      fields: {
        code: 'Code (ISO 639-1 / ISO 3166)',
        name: 'Name',
        default: 'Standard',
      },
    },
    content: {
      name: 'Inhalt |||| Inhalt',
      fields: {
        id: 'ID',
        key: 'Eindeutiger Schlüssel',
        name: 'Name',
        typo3groupId: 'TYPO3 GroupId',
      },
    },
    tests: {
      name: 'Test |||| Tests',
      fields: {
        id: 'ID',
        key: 'Eindeutiger Schlüssel',
        name: 'Name',
        attempts: 'Erlaubte Versuche',
        successTarget: 'Ziel zum Bestehen',
        byQuestions: 'Auswerten nach Fragen',
        typo3groupId: 'TYPO3 GroupId',
        config: 'JSON für Konfiguration des Testes',
      },
    },
    reminders: {
      name: 'Erinnerung |||| Erinnerungen',
      fields: {
        id: 'ID',
        key: 'Eindeutiger Schlüssel',
        sentAfterDays: 'Erinnerungen send nach Tagen',
        mailSubject: 'Mail Betreff',
        mailText: 'Mail Text',
      },
    },
    configs: {
      name: 'Konfiguration |||| Konfigurationen',
      fields: {
        key: 'Schlüssel',
        type: 'Typ',
        value: 'Wert',
      },
    },
    participants: {
      name: 'Teilnehmer |||| Teilnehmer',
      fields: {
        training: 'Training',
        users: 'Benutzer',
      },
    },
    participations: {
      name: 'Teilnahme |||| Teilnahmen',
      fields: {
        user: 'Benutzer',
        startDate: 'Startdatum',
        endDate: 'Enddatum',
        invitationSent: 'Einladungsversandtdatum',
        remindersSent: 'Versandte Erinnerungen',
        certificateSent: 'Zertifikatsversandtdatum',
        status: 'Status',
      },
      filter: {
        user: 'Benutzer',
        status: 'Status',
      },
      buttons: {
        createNewParticipation: 'Neue Teilnahme anlegen',
        sendInvitation: 'Einladung senden',
        sendReminder: 'Erinnerung senden',
        sendCertificate: 'Zertifikat senden',
        participantsExport: 'Export',
      },
      titles: {
        list: 'Teilnahmen des Trainings "%{name}"',
      },
      actions: {
        createNewParticipation: {
          start: 'Lege Teilnahme an ...',
          success: '... Teilnahme angelegt',
          failure: '... Fehler beim Anlegen der Teilnahme',
        },
        sendInvitation: {
          start: 'Sende Einladung ...',
          success: '... Einladung erfolgreich versand',
          failure: '... Fehler beim Versand der Einladung',
        },
        sendReminder: {
          start: 'Sende Erinnerung ...',
          success: '... Erinnerung erfolgreich versand',
          failure: '... Fehler beim Versand der Erinnerung',
        },
        sendCertificate: {
          start: 'Sende Zertifikat ...',
          success: '... Zertifikat erfolgreich versand',
          failure: '... Fehler beim Versand des Zertifikates',
        },
        participantsExport: {
          start: 'Exportiere ...',
          success: '... Export erfolgreich',
          failure: '... Fehler beim Export',
        },
      },
    },
    passwordChange: {
      name: 'Passwort ändern',
      fields: {
        oldPassword: 'Altes Passwort',
        newPassword: 'Neues Passwort',
        confirmedPassword: 'Neues Password bestätigen',
      },
    },
    tags: {
      name: 'Tag |||| Tags',
      fields: {
        value: 'Wert',
        isProperty: 'Ist Eigenschaft',
      },
      filter: {
        value: 'Wert',
      },
      buttons: {
        showPropertyTags: 'Eigenschaften-TAGs anzeigen',
      },
    },
    'property-tags': {
      name: 'Eigenschaften-TAG |||| Eigenschaften-TAGs',
      fields: {
        value: 'Wert',
        isProperty: 'Ist Eigenschaft',
      },
      filter: {
        value: 'Wert',
      },
    },
    groups: {
      name: 'Gruppe |||| Gruppen',
      fields: {
        key: 'Schlüssel',
        name: 'Name',
        parent: 'Elterngruppe',
        tags: 'Tags',
        rights: 'Rechte',
        supervisors: 'Führungskräfte',
        adminGroup: 'Nur für Admins sichtbar',
      },
    },
    tans: {
      name: 'Tan |||| Tans',
      fields: {
        tan: 'Tan',
        reserved: 'Reserviert',
        user: 'Benutzer',
        amount: 'Anzahl',
      },
    },
    usersImport: {
      name: 'Benutzer-Import',
      fields: {
        name: 'Name',
        file: 'CSV oder XLSX Datei',
        creationDate: 'Erstellungsdatum',
        executionStart: 'Ausführung gestartet um',
        executionFinish: 'Ausführung abgeschlossen um',
        executionDate: 'Ausführungsdatum',
        entries: 'Einträge',
        entry: {
          imported: 'Importiert',
          trainings: 'Trainings',
          userExists: 'Existiert',
          needsUpdate: 'Änderungen',
          foundVia: 'Gefunden durch',
        },
      },
      show: {
        title: 'Benutzer-Import-Vorschau',
        buttons: {
          execute: 'Import ausführen',
        },
        actions: {
          execute: {
            start: 'Importiere Benutzer ...',
            success: '... Benutzer erfolgreich importiert',
            failure: '... konnte nicht alle Benutzer erfolgreich importieren',
          },
        },
      },
    },
    importEntry: {
      name: 'Benutzer-Import-Eintrag',
      fields: {
        import: 'Import',
        firstName: 'Vorname',
        lastName: 'Nachname',
        foundFirstName: 'Gefundener Vorname',
        foundLastName: 'Gefundener Nachname',
        foundVia: 'Gefunden durch',
        tags: 'TAGs',
        trainings: 'Trainings',
        error: 'Error',
        done: 'Importiert um',
      },
    },
    importTagEntry: {
      name: 'Benutzer-Import-Tag',
      fields: {
        import: 'Import',
        tagId: 'ID',
        tagValue: 'Wert',
        tagIsProperty: 'Is Eigenschaften-TAG',
        error: 'Error',
        done: 'Importiert um',
      },
    },
    importTrainingEntry: {
      name: 'Benutzer-Import-Training',
      fields: {
        import: 'Import',
        trainingId: 'ID',
        trainingKey: 'Key',
        error: 'Error',
      },
    },
  },
};
export {ellisMessagesDE};
