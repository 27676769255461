/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/8/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

const _ = require('lodash');

/** deep merge function
 * @param {object} destinationObj
 * @param {*} srcValue
 * @returns {*}
 */
const merger = (destinationObj, srcValue) => {
  if (_.isUndefined(srcValue)) {
    return destinationObj;
  }
  if (_.isPlainObject(destinationObj) && _.isPlainObject(srcValue)) {
    return _.assignInWith(destinationObj, srcValue, merger);
  }
  return srcValue;
};

/**
 *  - limited to merging 2 objects for now *
 * @param {object} obj1
 * @param {object} obj2
 * @returns {object}
 */
const objectMerger = (obj1, obj2) => {
  return _.assignInWith({}, obj1, obj2, merger);
};

export {objectMerger};
