/**
 * Created by René Simon <rene.simon@huchler.it> on 2019-10-03.
 * Copyright © Dr. Huchler und Partner GbR 2019 - present
 */

import {NOTIFY} from './consts';

export const notifyAction = ({message, error, refresh = false}) => {
  return {
    type: NOTIFY,
    message,
    refresh,
    error,
  };
};
