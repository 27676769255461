/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/8/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

import FIELD_TYPES from '../../consts/field-types';
import {minIntValue, minStringLength, validJsonString} from '../../forms/form-utils/field-validators';

export default {
  i18nPath: 'resources.tests.fields.',
  fields: {
    key: {
      type: FIELD_TYPES.TEXT_FIELD,
      validatorFunctions: minStringLength(1),
    },
    name: {
      type: FIELD_TYPES.LANGUAGE_FIELD,
    },
    attempts: {
      type: FIELD_TYPES.NUMBER,
    },
    successTarget: {
      type: FIELD_TYPES.NUMBER,
      validatorFunctions: minIntValue(1),
    },
    byQuestions: {
      type: FIELD_TYPES.BOOLEAN,
    },
    typo3groupId: {
      type: FIELD_TYPES.NUMBER,
      validatorFunctions: minIntValue(1),
    },
    config: {
      type: FIELD_TYPES.TEXT_AREA,
      validatorFunctions: validJsonString(false),
    },
  },
};
