/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/13/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

import FIELD_TYPES from '../../consts/field-types';
import {minIntValue, minStringLength} from '../../forms/form-utils/field-validators';

// TODO [RS]: Missing field "ccReceivers" because I do not know how to do a list of strings input yet

export default {
  i18nPath: 'resources.reminders.fields.',
  fields: {
    key: {
      type: FIELD_TYPES.TEXT_FIELD,
      validatorFunctions: minStringLength(1),
    },
    sentAfterDays: {
      type: FIELD_TYPES.NUMBER,
      validatorFunctions: minIntValue(1),
    },
    mailSubject: {
      type: FIELD_TYPES.LANGUAGE_FIELD,
    },
    mailText: {
      type: FIELD_TYPES.LANGUAGE_FIELD_LONG,
    },
  },
};
