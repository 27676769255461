/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 02.09.17.
 * Copyright © Dr. Huchler & Partner 2017
 */

/**
 * Types of config values
 * @readonly
 * @enum {string}
 * @name CONFIG_TYPES
 */
const CONFIG_TYPES = {
  LANGUAGE_OBJECT: 'languageObject',
  STRING: 'string',
  ARRAY: 'array',
  JSON: 'json',
  BOOLEAN: 'boolean',
};

export default CONFIG_TYPES;
