/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on ${DATE}.
 * Copyright © Dr. Huchler & Partner 2017
 */

import React from 'react';
import * as adminOnRest from 'admin-on-rest';
import usersImportIcon from 'material-ui/svg-icons/file/file-upload';
import FileUploadIcon from 'material-ui/svg-icons/file/file-upload';
import TriggerButton from '../../mui/buttons/trigger-button';

const log = require('../../libs/utils/log').create('UserImport');

const {
  FileInput,
  FileField,
  Create,
  SimpleForm,
  Show,
  DateField,
  SimpleShowLayout,
  TextField,
  BooleanField,
  TextInput,
  List,
  Datagrid,
  ShowButton,
  DeleteButton,
  ReferenceField,
  ReferenceManyField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} = adminOnRest;

export const UsersImportIcon = usersImportIcon;

export const UsersImportForm = (props) => {
  return (
    <Create title="Import users" {...props}>
      <SimpleForm redirect="show">
        <TextInput source="name" label="resources.usersImport.fields.name" />
        <FileInput source="csvFile" label="resources.usersImport.fields.file">
          <FileField source="src" title="title"/>
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export const UsersImportShow = (props) => {
  const labelPrefix = 'resources.usersImport';
  const showPrefix = `${labelPrefix}.show`;
  const fieldPrefix = `${labelPrefix}.fields`;
  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  };
  log.info(`Show props`, {props});
  return (
    <Show title={`${showPrefix}.title`} {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <DateField source="creationDate" label={`${fieldPrefix}.creationDate`} options={dateOptions}/>
        <DateField source="executionStart" label={`${fieldPrefix}.executionStart`} options={dateOptions}/>
        <DateField source="executionFinish" label={`${fieldPrefix}.executionFinish`} options={dateOptions}/>
        <ReferenceManyField
          label="resources.importEntry.name"
          reference="import-entries"
          target="import"
          perPage={9999}>
          <Datagrid>
            <TextField source="user.firstName" label="resources.importEntry.fields.firstName" />
            <TextField source="user.lastName" label="resources.importEntry.fields.lastName" />
            <TextField source="foundVia" label="resources.importEntry.fields.foundVia" />
            <TextField source="oldUser.firstName" label="resources.importEntry.fields.foundFirstName" />
            <TextField source="oldUser.lastName" label="resources.importEntry.fields.foundLastName" />
            <TextField source="error.message" label="resources.importEntry.fields.error" />
            <ReferenceArrayField
              reference="import-tag-entries"
              source="tags"
              label="resources.importEntry.fields.tags">
              <SingleFieldList>
                <ChipField source="label" />
              </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField
              reference="import-training-entries"
              source="trainings"
              label="resources.importEntry.fields.trainings">
              <SingleFieldList>
                <ChipField source="label" />
              </SingleFieldList>
            </ReferenceArrayField>
            <DateField source="done" options={dateOptions} label="resources.importEntry.fields.done" />
            <ShowButton key="col.button.show" />
            <DeleteButton key="col.button.delete" />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField label="Tags" reference="import-tag-entries" target="import" perPage={9999}>
          <Datagrid>
            <TextField source="tagId" label="resources.importTagEntry.fields.tagId" />
            <TextField source="tagValue" label="resources.importTagEntry.fields.tagValue" />
            <BooleanField source="tagIsProperty" label="resources.importTagEntry.fields.tagIsProperty" />
            <TextField source="error.message" label="resources.importTagEntry.fields.error" />
            <DateField source="done" options={dateOptions} label="resources.importTagEntry.fields.done" />
            <ShowButton key="col.button.show" />
            <DeleteButton key="col.button.delete" />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField label="Trainings" reference="import-training-entries" target="import" perPage={9999}>
          <Datagrid>
            <TextField source="trainingId" label="resources.importTrainingEntry.fields.trainingId" />
            <TextField source="trainingKey" label="resources.importTrainingEntry.fields.trainingKey" />
            <TextField source="error.message" label="resources.importTrainingEntry.fields.error" />
            <ShowButton key="col.button.show" />
            <DeleteButton key="col.button.delete" />
          </Datagrid>
        </ReferenceManyField>
        <TriggerButton
          notifications={`${showPrefix}.actions.execute`}
          route="/imports/#{value}/execute"
          source="id"
          label={`${showPrefix}.buttons.execute`}
          icon={<FileUploadIcon/>}
          refreshAfter={true}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export const UsersImportList = (props) => {
  return (
    <List
      title="All User Imports"
      {...props}
    >
      <Datagrid>
        <TextField key="col.username" source="name"/>
        <ShowButton key="col.button.show" />
        <DeleteButton key="col.button.delete" />
      </Datagrid>
    </List>
  );
};

export const UsersImportEntryShow = (props) => {
  return (
    <Show title="User Import Entry" {...props}>
      <SimpleShowLayout>
        <ReferenceField source="import" reference="imports">
          <TextField source="name" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

export const UsersImportTrainingEntryShow = (props) => {
  return (
    <Show title="User Import Training Entry" {...props}>
      <SimpleShowLayout>
        <ReferenceField source="import" reference="imports" label="resources.importTrainingEntry.fields.import" >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="trainingId" label="resources.importTrainingEntry.fields.trainingId" />
        <TextField source="trainingKey" label="resources.importTrainingEntry.fields.trainingKey" />
        <TextField source="error.message" label="resources.importTrainingEntry.fields.error" />
      </SimpleShowLayout>
    </Show>
  );
};


export const UsersImportTagEntryShow = (props) => {
  return (
    <Show title="resources.importTagEntry.name" {...props}>
      <SimpleShowLayout>
        <ReferenceField source="import" reference="imports" label="resources.importTagEntry.fields.import" >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="tagId" label="resources.importTagEntry.fields.tagId" />
        <TextField source="tagValue" label="resources.importTagEntry.fields.tagValue" />
        <TextField source="tagIsProperty" label="resources.importTagEntry.fields.tagIsProperty" />
        <TextField source="error.message" label="resources.importTagEntry.fields.error" />
        <TextField source="done" label="resources.importTagEntry.fields.done" />
      </SimpleShowLayout>
    </Show>
  );
};
