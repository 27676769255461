/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on ${DATE}.
 * Copyright © Dr. Huchler & Partner 2017
 */

import FIELD_TYPES from '../../consts/field-types';
import {minStringLength} from '../../forms/form-utils/field-validators';

// TODO: [RS] Make Password field to password field --> new type. Also in user form

export default {
  i18nPath: 'resources.passwordChange.fields.',
  fields: {
    oldPassword: {
      type: FIELD_TYPES.PASSWORD_FIELD,
      validatorFunctions: minStringLength(1),
    },
    newPassword: {
      type: FIELD_TYPES.PASSWORD_FIELD,
      validatorFunctions: minStringLength(8),
    },
    confirmedPassword: {
      type: FIELD_TYPES.PASSWORD_FIELD,
      validatorFunctions: minStringLength(8),
    },
  },
};
