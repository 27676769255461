/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/13/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

import LanguageObjectField from '../../mui/fields/language-object-field';
import React from 'react';
import * as adminOnRest from 'admin-on-rest';
import testIcon from 'material-ui/svg-icons/action/assignment';
import schema from './schema';
import formGenerator from '../../forms/form-generator';
import ELLIS_RIGHTS from 'ellis-rights';

const {List, Datagrid, Edit, Create, SimpleForm, NumberField, BooleanField, EditButton, DisabledInput} = adminOnRest;

export const TestIcon = testIcon;

export const TestList = (props) => {
  return (
    <List title="All Tests" {...props}>
      {permissions => {
        const COLUMNS = [
          <LanguageObjectField key="col.name" source="name"/>,
          <NumberField key="col.attempts" source="attempts"/>,
          <NumberField key="col.successTarget" source="successTarget"/>,
          <BooleanField key="col.byQuestions" source="byQuestions"/>,
          <NumberField key="col.typo3groupId" source="typo3groupId"/>,
        ];
        if (permissions.hasRight(ELLIS_RIGHTS.TRAINING_TEST_UPDATE)) {
          COLUMNS.push(<EditButton key="col.button.edit" basePath="lms/tests"/>);
        }
        return <Datagrid>{COLUMNS}</Datagrid>;
      }}
    </List>
  );
};

export const TestEdit = (props) => (
  <Edit title="Edit Test" {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      {formGenerator.getInputFieldsBySchema(schema)}
    </SimpleForm>
  </Edit>
);


export const TestCreate = (props) => {
  return (
    <Create title="Create a Test" {...props}>
      <SimpleForm>
        {formGenerator.getInputFieldsBySchema(schema)}
      </SimpleForm>
    </Create>
  );
};
