/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/13/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

import LanguageObjectField from '../../mui/fields/language-object-field';
import React from 'react';
import * as adminOnRest from 'admin-on-rest';
import reminderIcon from 'material-ui/svg-icons/image/filter-9-plus';
import schema from './schema';
import formGenerator from '../../forms/form-generator';
import ELLIS_RIGHTS from 'ellis-rights';

const {List, Datagrid, Edit, Create, SimpleForm, EditButton, TextField, NumberField, DisabledInput} = adminOnRest;

export const ReminderIcon = reminderIcon;

export const ReminderList = (props) => {
  return (
    <List title="All Reminders" {...props}>
      {permissions => {
        const COLUMNS = [
          <TextField key="col.id" source="id"/>,
          <NumberField key="col.sentAfterDays" source="sentAfterDays"/>,
          <LanguageObjectField key="col.mailSubject" source="mailSubject"/>,
          <LanguageObjectField key="col.mailText" source="mailText"/>,
        ];
        if (permissions.hasRight(ELLIS_RIGHTS.TRAINING_REMINDER_UPDATE)) {
          COLUMNS.push(<EditButton key="col.button.edit" basePath="lms/reminders"/>);
        }
        return <Datagrid>{COLUMNS}</Datagrid>;
      }}
    </List>
  );
};

export const ReminderEdit = (props) => (
  <Edit title="Edit Reminder" {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      {formGenerator.getInputFieldsBySchema(schema)}
    </SimpleForm>
  </Edit>
);


export const ReminderCreate = (props) => {
  return (
    <Create title="Create Reminder" {...props}>
      <SimpleForm>
        {formGenerator.getInputFieldsBySchema(schema)}
      </SimpleForm>
    </Create>
  );
};
