/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 22.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

/**
 * @typedef {ReduxAction} TriggerAction
 * @property {string} notifications
 * @property {string} route
 * @property {string} source
 * @property {Object} record
 * @property {Error} [error]
 */

import {all, put, takeEvery} from 'redux-saga/effects';
import {refreshView, changeListParams} from 'admin-on-rest';
import {SET_LIST_PARAMS_ACTION} from './set-list-params-action';

/**
 * Handles trigger success
 * @param {TriggerAction} action
 */
function* setListParams(action) {
  const {resource, params} = action;
  yield put(changeListParams(resource, params));
  yield put(refreshView());
}

/**
 * Sage for the trigger action
 */
export default function* setListParamsSaga() {
  yield all([
    takeEvery(SET_LIST_PARAMS_ACTION, setListParams),
  ]);
}
