/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 22.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

export const TRIGGER_ACTION = 'TRIGGER_ACTION';
export const triggerAction = (notifications, route, source, source2, record, refreshAfter) => {
  return {
    type: TRIGGER_ACTION,
    notifications,
    route,
    source,
    source2,
    record,
    refreshAfter,
  };
};
