/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on ${DATE}.
 * Copyright © Dr. Huchler & Partner 2017
 */

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

require('./libs/utils/log').init(window);

ReactDOM.render(<App/>, document.getElementById('root'));
registerServiceWorker();
