/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 22.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

/**
 * @typedef {ReduxAction} DownloadAction
 * @property {string} notifications
 * @property {string} route
 * @property {string} [source]
 * @property {Object} [record]
 * @property {Error} [error]
 */

import {all, call, put, takeEvery} from 'redux-saga/effects';
import {showNotification} from 'admin-on-rest';
import {DOWNLOAD_ACTION} from './download-action';
import actionNotification from '../../libs/utils/action-notification';
import system from '../../libs/utils/system';

const DOWNLOAD_ACTION_SUCCESS = 'DOWNLOAD_ACTION_SUCCESS';
const DOWNLOAD_ACTION_FAILURE = 'DOWNLOAD_ACTION_FAILURE';

const log = require('../../libs/utils/log').create('DownloadSage');

/**
 * Creating an action
 * @param {string} type
 * @param {DownloadAction} action
 * @param {Error} [error]
 * @return {TriggerAction}
 */
function createAction(type, action, error) {
  const {notifications, route, source, record} = action;
  const newAction = {
    type,
    notifications,
    route,
    source,
    record,
  };
  if (error) {
    newAction.error = error;
  }
  return newAction;
}

/**
 * Trigger action
 * @param {DownloadAction} action
 */
function* executeAction(action) {
  try {
    log.info('Received action', action);
    let {notifications, route, source, record} = action;
    yield put(showNotification(actionNotification(notifications).START));
    if (record && source) {
      route = route.replace('#{value}', record[source]);
    }
    const url = system.getApiUrl(true) + route;
    yield call(() => {
      return new Promise((resolve, reject) => {
        try {
          const downloadWindow = window.open(url, 'Ellis-Download');
          downloadWindow.onload = () => {
            log.info('Download done');
            downloadWindow.close();
          };
          downloadWindow.onbeforeunload = () => {
            log.info('Download window closed');
            resolve();
          };
        } catch (error) {
          reject(error);
        }
      });
    });
    log.info(`Success in download "${url}"`);
    yield put(createAction(DOWNLOAD_ACTION_SUCCESS, action));
  } catch (error) {
    yield put(createAction(DOWNLOAD_ACTION_FAILURE, action, error));
  }
}

/**
 * Handles download success
 * @param {DownloadAction} action
 */
function* downloadSuccess(action) {
  const {notifications} = action;
  yield put(showNotification(actionNotification(notifications).SUCCESS));
}

/**
 * Handle download failure
 * @param {DownloadAction} action
 */
function* downloadFailure(action) {
  const {notifications, error} = action;
  log.warn('Failed in download action', error);
  yield put(showNotification(actionNotification(notifications).FAILURE, 'warning'));
}

/**
 * Sage for the download action
 */
export default function* downloadSaga() {
  yield all([
    takeEvery(DOWNLOAD_ACTION, executeAction),
    takeEvery(DOWNLOAD_ACTION_SUCCESS, downloadSuccess),
    takeEvery(DOWNLOAD_ACTION_FAILURE, downloadFailure),
  ]);
}
