/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/13/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import * as adminOnRest from 'admin-on-rest';
import participationsIcon from 'material-ui/svg-icons/action/assignment';
import LengthField from '../../mui/fields/length-field';
import TriggerButton from '../../mui/buttons/trigger-button';
import SendIcon from 'material-ui/svg-icons/content/send';
import AddIcon from 'material-ui/svg-icons/content/add';
import DownloadButton from '../../mui/buttons/download-button';
import FileDownloadIcon from 'material-ui/svg-icons/file/file-download';
import {getSelectedLanguageCode} from '../../libs/utils/languages';
import restClient from '../../libs/rest-client';
import ELLIS_RIGHTS from 'ellis-rights';

const {
  List, Datagrid, DateField, TextInput, Filter,
  ReferenceField, TextField, GET_ONE, showNotification, WithPermission,
} = adminOnRest;
const log = require('../../libs/utils/log').create('participations');

export const ParticipationsIcon = participationsIcon;

const ParticipationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="resources.participations.filter.user" source="user"/>
    <TextInput label="resources.participations.filter.status" source="status"/>
  </Filter>
);

/**
 * List participations
 */
class ParticipationsList extends React.Component {
  /**
   * Construct this component
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    let {match} = props;
    match = match || {};
    const params = match.params || {};
    const id = params.id || null;
    this.state = {
      id,
      training: {
        key: '...retrieving...',
        name: {},
      },
    };
  }

  /**
   * Executed after the react component was mounted (react component live cycle)
   */
  componentDidMount() {
    const id = this.state.id;
    if (id) {
      restClient(GET_ONE, 'lms/trainings', {id}).then(({data}) => {
        this.setState({training: data});
      }).catch(error => {
        log.error(`Failed to retrieve training for id "${id}"`, error);
        showNotification('Failed to retrieve training', 'error');
        this.setState({
          training: {
            key: '... failed to retrieve name ...',
            name: {},
          },
        });
      });
    } else {
      this.setState({
        training: {
          key: '... no training id ...',
          name: {},
        },
      });
    }
  }

  /**
   * Default render method
   * @return {xml}
   */
  render() {
    const {id, training} = this.state;
    const {translate} = this.context;
    const props = this.props;
    const locale = getSelectedLanguageCode();
    const {
      name = {},
      key,
    } = training;
    const trainingName = name[locale] || key;
    const title = translate('resources.participations.titles.list', {name: trainingName});
    return (
      <div>
        <WithPermission resolve={({permissions}) => permissions.hasRight(ELLIS_RIGHTS.USER_IMPORT)}>
          <DownloadButton
            notifications="resources.participations.actions.participantsExport"
            route={`/lms/exports/participants/${id}?locale=${locale}`}
            label="resources.participations.buttons.participantsExport"
            icon={<FileDownloadIcon/>}
          />
        </WithPermission>
        <List
          title={title}
          {...props}
          filters={<ParticipationFilter/>}
          filter={{training: id, twoLetterCode: getSelectedLanguageCode()}}>
          {permissions => {
            const COLUMNS = [
              <ReferenceField
                key="col.user"
                label="resources.participations.fields.user"
                source="user"
                reference="users"
                sortable={false}>
                <TextField source="label"/>
              </ReferenceField>,
              <DateField key="col.startDate"
                source="startDate" label="resources.participations.fields.startDate"/>,
              <DateField key="col.endDate"
                source="endDate" label="resources.participations.fields.endDate"/>,
              <DateField key="col.invitationSent"
                source="invitationSent" label="resources.participations.fields.invitationSent"/>,
              <LengthField key="col.remindersSent"
                source="remindersSent" label="resources.participations.fields.remindersSent"/>,
              <TextField key="col.status"
                source="status" label="resources.participations.fields.status" sortable={false}/>,
              <DateField key="col.certificateSent"
                source="certificateSent" label="resources.participations.fields.certificateSent"/>,
            ];
            if (permissions.hasRight(ELLIS_RIGHTS.PARTICIPANTS_SHOW)) {
              COLUMNS.push(
                <TriggerButton
                  key="col.button.createNewParticipation"
                  notifications="resources.participations.actions.createNewParticipation"
                  route="/lms/participants/#{value}/createParticipation/#{value2}"
                  source="training"
                  source2="user"
                  label="resources.participations.buttons.createNewParticipation"
                  icon={<AddIcon/>}
                  refreshAfter={true}
                  sortable={false}
                />,
              );
            }
            if (permissions.hasRight(ELLIS_RIGHTS.PARTICIPATION_INVITE)) {
              COLUMNS.push(
                <TriggerButton
                  key="col.button.sendInvitation"
                  notifications="resources.participations.actions.sendInvitation"
                  route="/lms/participations/#{value}/invite"
                  source="id"
                  label="resources.participations.buttons.sendInvitation"
                  icon={<SendIcon/>}
                  refreshAfter={true}
                  sortable={false}
                />,
              );
            }
            if (permissions.hasRight(ELLIS_RIGHTS.PARTICIPATION_REMIND)) {
              COLUMNS.push(
                <TriggerButton
                  key="col.button.sendReminder"
                  notifications="resources.participations.actions.sendReminder"
                  route="/lms/participations/#{value}/remind"
                  source="id"
                  label="resources.participations.buttons.sendReminder"
                  icon={<SendIcon/>}
                  refreshAfter={true}
                  sortable={false}
                />,
              );
            }
            if (permissions.hasRight(ELLIS_RIGHTS.PARTICIPATION_CERTIFICATE)) {
              COLUMNS.push(
                <TriggerButton
                  key="col.button.sendCertificate"
                  notifications="resources.participations.actions.sendCertificate"
                  route="/lms/participations/#{value}/certificate"
                  source="id"
                  label="resources.participations.buttons.sendCertificate"
                  icon={<SendIcon/>}
                  refreshAfter={true}
                  sortable={false}
                />,
              );
            }
            if (permissions.hasRight(ELLIS_RIGHTS.USER_UPDATE)) {
              COLUMNS.push(
                <TriggerButton
                  key="col.button.resetPassword"
                  notifications="resources.users.actions.resetPassword"
                  route="lms/participations/#{value}/reset-password"
                  source="id"
                  label="resources.users.buttons.resetPassword"
                  icon={<SendIcon/>}
                  refreshAfter={false}
                  sortable={false}
                />);
            }
            return <Datagrid>{COLUMNS}</Datagrid>;
          }}
        </List>
      </div>
    );
  }
}

ParticipationsList.propTypes = {
  match: PropTypes.object,
};

ParticipationsList.contextTypes = {
  translate: PropTypes.func,
};

export default ParticipationsList;
