/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 03.10.19.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Field, propTypes, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {Card, CardActions} from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import CircularProgress from 'material-ui/CircularProgress';
import {translate} from 'admin-on-rest';
import LinkButton from '../../mui/buttons/link-button';
import {requestSetPasswordAction} from '../../redux/password-forgotten/actions';
import {minStringLength} from '../../forms/form-utils/field-validators';

const validatePassword = minStringLength(8);

const styles = {
  card: {
    minWidth: 300,
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    display: 'flex',
  },
};

const renderInput = ({
  meta: {touched, error} = {},
  input: {...inputProps},
  ...props
}) => (
  <TextField
    errorText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

renderInput.propTypes = {
  meta: PropTypes.object.isRequired,
};

class PasswordForgotten extends Component {
  submit = ({newPassword, confirmedPassword}) => {
    const {
      requestSet,
      history,
    } = this.props;
    requestSet({
      history,
      newPassword,
      confirmedPassword,
      notifications: 'ellis.forms.passwordSet.actions.requestPasswordSet',
    });
  }

  render() {
    const {handleSubmit, isLoading, translate} = this.props;
    return (
      <Card style={styles.card}>
        <form onSubmit={handleSubmit(this.submit)}>
          <div style={styles.form}>
            <div style={styles.input}>
              <Field
                name="newPassword"
                component={renderInput}
                floatingLabelText={translate(
                  'ellis.forms.passwordSet.newPassword',
                )}
                type="password"
                disabled={isLoading}
              />
            </div>
            <div style={styles.input}>
              <Field
                name="confirmedPassword"
                component={renderInput}
                floatingLabelText={translate(
                  'ellis.forms.passwordSet.confirmedPassword',
                )}
                type="password"
                disabled={isLoading}
              />
            </div>
          </div>
          <CardActions>
            <RaisedButton
              type="submit"
              primary
              disabled={isLoading}
              icon={
                isLoading && (
                  <CircularProgress
                    size={25}
                    thickness={2}
                  />
                )
              }
              label={translate('ellis.forms.passwordSet.submit')}
              fullWidth
            />
          </CardActions>
        </form>
        <LinkButton
          primary
          disabled={isLoading}
          label="ellis.forms.passwordSet.back"
          route="/login"
          fullWidth
        />
      </Card>
    );
  }
}

PasswordForgotten.propTypes = {
  ...propTypes,
  translate: PropTypes.func.isRequired,
  requestSet: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({isLoading: state.admin.loading > 0});

const enhance = compose(
  translate,
  reduxForm({
    form: 'passwordForgotten',
    validate: (values, props) => {
      const errors = {};
      const {translate} = props;
      const {newPassword, confirmedPassword} = values || {};
      if (!newPassword) {
        errors.newPassword = translate('aor.validation.required');
      } else {
        const passwordError = validatePassword(newPassword, values, props);
        if (passwordError) {
          errors.newPassword = passwordError;
        }
      }
      if (newPassword !== confirmedPassword) {
        errors.confirmedPassword = translate('ellis.forms.passwordSet.errors.notMatch');
      }
      return errors;
    },
  }),
  connect(mapStateToProps, {requestSet: requestSetPasswordAction}),
);

export default enhance(PasswordForgotten);
