/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 05.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import {changeLocale as changeLocaleAction, refreshView as refreshViewAction} from 'admin-on-rest';
import {setSelectedLanguageCode} from '../../libs/utils/languages';
import {ellisTextDark} from '../../styles/ellis-colors';

/**
 * Generates the input field for the config value
 */
class LanguageChangeButton extends Component {
  /**
   * Handles the click on the button
   */
  handleClick() {
    const {changeLocale, refreshView, locale} = this.props;
    setSelectedLanguageCode(locale);
    changeLocale(locale);
    refreshView();
  }

  /**
   * Standard render function
   * @return {xml}
   */
  render() {
    const {locale} = this.props;
    const textColor = this.props.textColor || ellisTextDark;
    const onClick = this.handleClick.bind(this);
    const buttonStyle = Object.assign({}, {color: textColor}, {overflow: 'inherit'});

    return <FlatButton label={locale} onClick={onClick} style={buttonStyle}/>;
  }
}

LanguageChangeButton.propTypes = {
  changeLocale: PropTypes.func,
  refreshView: PropTypes.func,
  locale: PropTypes.string.isRequired,
  textColor: PropTypes.string,
};

export default connect(null, {changeLocale: changeLocaleAction, refreshView: refreshViewAction})(LanguageChangeButton);
