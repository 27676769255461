/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/13/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */
/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/13/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

/**
 * @type {Schema}
 */
import schema from './schema';
import LanguageObjectField from '../../mui/fields/language-object-field';
import React from 'react';
import * as adminOnRest from 'admin-on-rest';
import contentIcon from 'material-ui/svg-icons/av/art-track.js';
import formGenerator from '../../forms/form-generator';
import ELLIS_RIGHTS from 'ellis-rights';

const {List, Datagrid, Edit, Create, SimpleForm, NumberField, EditButton, TextField, DisabledInput} = adminOnRest;

export const ContentIcon = contentIcon;

export const ContentList = (props) => {
  return (
    <List title="All Content" {...props}>
      {permissions => {
        const COLUMNS = [
          <TextField key="col.id" source="id"/>,
          <LanguageObjectField key="col.name" source="name"/>,
          <NumberField key="col.typo3groupId" source="typo3groupId"/>,
        ];
        if (permissions.hasRight(ELLIS_RIGHTS.TRAINING_CONTENT_UPDATE)) {
          COLUMNS.push(<EditButton key="col.button.edit" basePath="lms/content"/>);
        }
        return <Datagrid>{COLUMNS}</Datagrid>;
      }}
    </List>
  );
};

export const ContentEdit = (props) => (
  <Edit title="Edit Content" {...props}>
    <SimpleForm>
      <DisabledInput source="id"/>
      {formGenerator.getInputFieldsBySchema(schema)}
    </SimpleForm>
  </Edit>
);

export const ContentCreate = (props) => {
  return (
    <Create title="Create Content" {...props}>
      <SimpleForm>
        {formGenerator.getInputFieldsBySchema(schema)}
      </SimpleForm>
    </Create>
  );
};
