/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/12/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */


export const ellisTextDark = '#3c3939';
export const ellisTextLight = '#ffffff';
export const ellisBckGrndWhite = '#ffffff';
export const ellisPaynesGrey = '#092123';
export const ellisMidGrey = '#5e6a6c';
export const ellisLightBlue = '#79a1a3';
export const ellisAlertPink = '#f36556';
export const ellisAlertGreen = '#bfd987';
export const ellisAlertYellow = '#f1eeaf';
export const ellisShadow = '#3d3b3e';
