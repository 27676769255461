/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 22.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

export const SET_LIST_PARAMS_ACTION = 'ELLIS/SET_LIST_PARAMS_ACTION';
export const setListParamsAction = (resource, params) => {
  return {
    type: SET_LIST_PARAMS_ACTION,
    resource,
    params,
  };
};
