/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 05.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tab, Tabs} from 'material-ui/Tabs';
import * as aor from 'admin-on-rest';
import {Field} from 'redux-form';
import {getLanguageCodes} from '../../libs/utils/languages';

const log = require('../../libs/utils/log').create('LOI');

const {TextInput, LongTextInput} = aor;

/**
 * Generates the input field for the config value
 */
class LanguageObjectInput extends Component {
  /**
   * Standard render function
   * @return {xml}
   */
  render() {
    const props = this.props;
    const {record, source, resource, long} = props;
    const languageCodes = getLanguageCodes();
    const comp = long ? LongTextInput : TextInput;
    log.info({record, source, resource, long, languageCodes, comp});
    record[source] = record[source] || {};
    const tabs = languageCodes.map(code => {
      const id = `${resource}-${source}-${code}`;
      return <Tab label={code} key={`tab-${id}`}>
        <div>
          <Field
            key={`field-${id}`} name={`${source}.${code}`} component={comp} type="string"
            placeholder={`Translation for "${code}" for property "${source}"`} options={{fullWidth: true}}
          />
        </div>
      </Tab>;
    });
    return <Tabs>{tabs}</Tabs>;
  }
}

LanguageObjectInput.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string.isRequired,
  long: PropTypes.bool,
  showNotification: PropTypes.func,
};

export default LanguageObjectInput;
