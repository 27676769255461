/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on ${DATE}.
 * Copyright © Dr. Huchler & Partner 2017
 */

import FIELD_TYPES from '../../consts/field-types';
import {allCountries, languagesAndCodes, statesAndCodesByCountry} from '../../forms/form-utils/geographical-lists';
import {minStringLength, validEmailAddress} from '../../forms/form-utils/field-validators';
import mongoIdsParser from '../../redux/parser/mongo-ids-parser';
import {getCurrentUser} from '../../libs/utils/current-user';
import {getConfig} from '../../libs/utils/config';

/**
 * @typedef {Object} User
 * @property {string} username
 * @property {string} password
 * @property {string} staffNumber
 * @property {string} firstName
 * @property {string} lastName
 */
// TODO: [RS] Complete the typedef of user

function fieldDisabled(fieldName) {
  let hiddenFields = null;
  return () => {
    if (!hiddenFields) {
      const config = getConfig('frontend.hiddenUserFields') || '';
      hiddenFields = config.split(',').reduce((memo, fieldName) => {
        memo[fieldName.trim()] = true;
        return memo;
      }, {});
    }
    return hiddenFields[fieldName];
  };
}

export default {
  i18nPath: 'resources.users.fields.',
  fields: {
    username: {
      disabled: fieldDisabled('username'),
      type: FIELD_TYPES.TEXT_FIELD,
      validatorFunctions: minStringLength(6),
    },
    password: {
      disabled: fieldDisabled('password'),
      type: FIELD_TYPES.PASSWORD_FIELD,
      validatorFunctions: minStringLength(8),
    },
    staffNumber: {
      disabled: fieldDisabled('staffNumber'),
      type: FIELD_TYPES.TEXT_FIELD,
    },
    firstName: {
      disabled: fieldDisabled('firstName'),
      type: FIELD_TYPES.TEXT_FIELD,
      validatorFunctions: minStringLength(1),
    },
    middleName: {
      disabled: fieldDisabled('middleName'),
      type: FIELD_TYPES.TEXT_FIELD,
    },
    lastName: {
      disabled: fieldDisabled('lastName'),
      type: FIELD_TYPES.TEXT_FIELD,
      validatorFunctions: minStringLength(1),
    },
    title: {
      disabled: fieldDisabled('title'),
      type: FIELD_TYPES.TEXT_FIELD,
    },
    tags: {
      disabled: fieldDisabled('tags'),
      type: FIELD_TYPES.REFERENCE_ARRAY_SELECT,
      resource: 'tags',
      source: 'id',
      optionLabelSource: 'value',
      parser: mongoIdsParser,
      filter: {isProperty: false},
    },
    address: {
      disabled: fieldDisabled('address'),
      type: FIELD_TYPES.TEXT_FIELD,
    },
    zip: {
      disabled: fieldDisabled('zip'),
      type: FIELD_TYPES.TEXT_FIELD,
    },
    city: {
      disabled: fieldDisabled('city'),
      type: FIELD_TYPES.TEXT_FIELD,
    },
    state: {
      disabled: fieldDisabled('state'),
      type: FIELD_TYPES.SELECT,
      optionsList: statesAndCodesByCountry.Germany,
    },
    country: {
      disabled: fieldDisabled('country'),
      type: FIELD_TYPES.SELECT,
      optionsList: allCountries,
    },
    landLinePhone: {
      disabled: fieldDisabled('landLinePhone'),
      type: FIELD_TYPES.TEXT_FIELD,
    },
    mobilePhone: {
      disabled: fieldDisabled('mobilePhone'),
      type: FIELD_TYPES.TEXT_FIELD,
    },
    fax: {
      disabled: fieldDisabled('fax'),
      type: FIELD_TYPES.TEXT_FIELD,
    },
    www: {
      disabled: fieldDisabled('www'),
      type: FIELD_TYPES.TEXT_FIELD,
    },
    businessEmail: {
      disabled: fieldDisabled('businessEmail'),
      type: FIELD_TYPES.EMAIL_FIELD,
      validatorFunctions: validEmailAddress(false),
    },
    privateEmail: {
      disabled: fieldDisabled('privateEmail'),
      type: FIELD_TYPES.EMAIL_FIELD,
      validatorFunctions: validEmailAddress(false),
    },
    company: {
      disabled: fieldDisabled('company'),
      type: FIELD_TYPES.TEXT_FIELD,
    },
    position: {
      disabled: fieldDisabled('position'),
      type: FIELD_TYPES.TEXT_FIELD,
    },
    costCenter: {
      disabled: fieldDisabled('costCenter'),
      type: FIELD_TYPES.TEXT_FIELD,
    },
    dateOfBirth: {
      disabled: fieldDisabled('dateOfBirth'),
      type: FIELD_TYPES.DATE_PICKER,
    },
    gender: {
      disabled: fieldDisabled('gender'),
      type: FIELD_TYPES.SELECT,
      optionsList: ['male', 'female', 'other'],
    },
    language: {
      disabled: fieldDisabled('language'),
      type: FIELD_TYPES.SELECT,
      optionsList: languagesAndCodes,
    },
    acceptedTermsAndConditions: {
      disabled: fieldDisabled('acceptedTermsAndConditions'),
      type: FIELD_TYPES.BOOLEAN,
    },
    selfRegistered: {
      disabled: fieldDisabled('selfRegistered'),
      type: FIELD_TYPES.BOOLEAN,
    },
    isCustomerAdmin: {
      disabled: () => !getCurrentUser('isAdmin'),
      type: FIELD_TYPES.BOOLEAN,
    },
    isAdmin: {
      disabled: () => !getCurrentUser('isAdmin'),
      type: FIELD_TYPES.BOOLEAN,
    },
  },
};
