/**
 * Created by Markus Faust <markus.faust@visart.eu> on 9/8/2017.
 * Copyright © Dr. Huchler & Partner 2017
 */

import FIELD_TYPES from '../../consts/field-types';
import {minIntValue, minStringLength, validEmailAddress} from '../../forms/form-utils/field-validators';
import mongoIdsParser from '../../redux/parser/mongo-ids-parser';

export default {
  i18nPath: 'resources.trainings.fields.',
  fields: {
    key: {
      type: FIELD_TYPES.TEXT_FIELD,
      editType: FIELD_TYPES.DISABLED_FIELD,
      validatorFunctions: minStringLength(1),
    },
    name: {
      type: FIELD_TYPES.LANGUAGE_FIELD,
    },
    description: {
      type: FIELD_TYPES.LANGUAGE_FIELD_LONG,
    },
    startDate: {
      type: FIELD_TYPES.DATE_PICKER,
    },
    endDate: {
      type: FIELD_TYPES.DATE_PICKER,
    },
    durationInDays: {
      type: FIELD_TYPES.NUMBER,
      validatorFunctions: minIntValue(0),
    },
    requiredTrainings: {
      type: FIELD_TYPES.REFERENCE_ARRAY_SELECT,
      resource: 'lms/trainings',
      source: 'id',
      optionLabelSource: 'key',
      parser: mongoIdsParser,
    },
    trainingsEndOptionContent: {
      type: FIELD_TYPES.SELECT,
      optionsList: [
        ['Always allowed', 'never'],
        ['Denied', 'test'],
        ['Denied after Participation-End-Date', 'endDate'],
      ],
    },
    trainingsEndOptionTest: {
      type: FIELD_TYPES.SELECT,
      optionsList: [
        ['Always allowed', 'never'],
        ['Denied', 'test'],
        ['Denied after Participation-End-Date', 'endDate'],
      ],
    },
    testEvaluationOption: {
      type: FIELD_TYPES.SELECT,
      optionsList: [
        ['First Test taken', 'first'],
        ['Last Test Taken', 'last'],
        ['Best Result', 'best'],
        ['Worst Result', 'worst'],
      ],
    },
    backupEmail: {
      type: FIELD_TYPES.EMAIL_FIELD,
      validatorFunctions: validEmailAddress(false),
    },
    trainers: {
      type: FIELD_TYPES.REFERENCE_ARRAY_SELECT,
      resource: 'users',
      source: 'id',
      optionLabelSource: 'label',
      parser: mongoIdsParser,
    },
    invitationMailSubject: {
      type: FIELD_TYPES.LANGUAGE_FIELD,
    },
    invitationMailContent: {
      type: FIELD_TYPES.LANGUAGE_FIELD_LONG,
    },
    reminders: {
      type: FIELD_TYPES.REFERENCE_ARRAY_SELECT,
      resource: 'lms/reminders',
      source: 'id',
      optionLabelSource: 'key',
      parser: mongoIdsParser,
    },
    certificateMailCC: {
      type: FIELD_TYPES.EMAIL_FIELD,
      validatorFunctions: validEmailAddress(false),
    },
    certificateMailSubject: {
      type: FIELD_TYPES.LANGUAGE_FIELD,
    },
    certificateMailContent: {
      type: FIELD_TYPES.LANGUAGE_FIELD_LONG,
    },
    certificatePdfContent: {
      type: FIELD_TYPES.LANGUAGE_FIELD_LONG,
    },
    certificateOrientation: {
      type: FIELD_TYPES.RADIO_BUTTONS,
      optionsList: [['Portrait', 'portrait'], ['Landscape', 'landscape']],
    },
    certificateSignUrl: {
      type: FIELD_TYPES.TEXT_FIELD,
    },
    content: {
      type: FIELD_TYPES.REFERENCE_ARRAY_SELECT,
      resource: 'lms/content',
      source: 'id',
      optionLabelSource: 'key',
      parser: mongoIdsParser,
    },
    tests: {
      type: FIELD_TYPES.REFERENCE_ARRAY_SELECT,
      resource: 'lms/tests',
      source: 'id',
      optionLabelSource: 'key',
      parser: mongoIdsParser,
    },
    layout: {
      type: FIELD_TYPES.LANGUAGE_FIELD_LONG,
    },
    invitationMailTemplate: {
      type: FIELD_TYPES.LANGUAGE_FIELD_LONG,
    },
    reminderMailTemplate: {
      type: FIELD_TYPES.LANGUAGE_FIELD_LONG,
    },
    certificateMailTemplate: {
      type: FIELD_TYPES.LANGUAGE_FIELD_LONG,
    },
    certificatePdfTemplate: {
      type: FIELD_TYPES.LANGUAGE_FIELD_LONG,
    },
  },
};
