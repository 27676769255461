/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 20.11.17.
 * Copyright © Dr. Huchler & Partner 2017
 */

import React from 'react';
import * as aor from 'admin-on-rest';
import formGenerator from '../../forms/form-generator';
import schema from './schema';
import icon from 'material-ui/svg-icons/social/group';

const {Edit, SimpleForm} = aor;

export const PasswordChangeIcon = icon;

export const PasswordChangeEdit = (props) => (
  <Edit title="Change password" {...props}>
    <SimpleForm redirect={false}>
      {formGenerator.getInputFieldsBySchema(schema)}
    </SimpleForm>
  </Edit>
);
