/**
 * Created by René Simon <rene.simon@huchler.it> on 2019-10-03.
 * Copyright © Dr. Huchler und Partner GbR 2019 - present
 */

import triggerSaga from './redux/trigger/trigger-sage';
import downloadSaga from './redux/download/download-sage';
import setListParamsSaga from './redux/set-list-params/set-list-params-sage';
import notifySage from './redux/notify/saga';
import passwordForgottenSage from './redux/password-forgotten/saga';

export const customSagas = [
  triggerSaga,
  downloadSaga,
  setListParamsSaga,
  notifySage,
  passwordForgottenSage,
];
