/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 08.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import * as aor from 'admin-on-rest';
import url from './url';
import cookie from './cookie';
import system from './system';

const LOCALE_KEY = `${system.getName()}-locale`;

const {resolveBrowserLocale} = aor;
const log = require('./log').create('languages');

let languageCodes = [];
let defaultLanguageCode = 'en';
let selectedLanguageCode = url.getParams('locale') || cookie.get(LOCALE_KEY) || resolveBrowserLocale() || 'en';
let client = null;

export async function initLanguages(authClient) {
  if (client && languageCodes.length) {
    return;
  }
  client = authClient;
  return updateLanguages();
}

/**
 * Updates all existing languages
 * @return {Promise}
 */
async function updateLanguages() {
  try {
    if (!client) {
      throw new Error('No language client given');
    }
    const {data: languages} = await client.get('/lms/languages');
    log.info('Got languages', languages);
    languageCodes = languages.map(language => language.code);
    defaultLanguageCode = languages.find(({default: d}) => !!d).code;
  } catch (error) {
    log.error('Failed to update languages', error);
    throw error;
  }
}

/**
 * Returns the list of current language codes
 * @return {Array.<string>}
 */
export function getLanguageCodes() {
  return languageCodes;
}

/**
 * Returns the default language code
 * @return {string}
 */
export function getDefaultLanguageCode() {
  return defaultLanguageCode;
}

/**
 * Returns the selected language code
 * @return {string}
 */
export function getSelectedLanguageCode() {
  return selectedLanguageCode;
}

/**
 * Sets the selected language code
 * @param {string} locale
 */
export function setSelectedLanguageCode(locale) {
  cookie.set(LOCALE_KEY, locale);
  selectedLanguageCode = locale;
}
