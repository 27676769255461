/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 05.09.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

let currentUser = null;

function injectCurrentUser(user) {
  currentUser = user;
}

export function getCurrentUser(field = null) {
  if (field) {
    if (!currentUser) {
      throw new Error('No current user');
    }
    return currentUser[field];
  }
  return currentUser;
}

export default {
  injectCurrentUser,
  get: getCurrentUser,
};
