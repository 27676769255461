/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 03.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import FIELD_TYPES from '../../consts/field-types';
import {validLanguageCode, validNonEmptyString} from '../../forms/form-utils/field-validators';

export default {
  i18nPath: 'resources.languages.fields.',
  fields: {
    code: {
      type: FIELD_TYPES.TEXT_FIELD,
      validatorFunctions: validLanguageCode,
    },
    name: {
      type: FIELD_TYPES.TEXT_FIELD,
      validatorFunctions: validNonEmptyString,
    },
    default: {
      type: FIELD_TYPES.BOOLEAN,
    },
  },
};
