/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 05.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React from 'react';
import PropTypes from 'prop-types';
import * as aor from 'admin-on-rest';

const {translate} = aor;

const TranslatedLabel = translate(({translate, label}) => (
  <span>{translate(label)}</span>
));
TranslatedLabel.propTypes = {
  translate: PropTypes.func,
  label: PropTypes.string.isRequired,
};

export default TranslatedLabel;
