/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 05.10.17.
 * Copyright © Dr. Huchler und Partner 2017
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import TranslatedLabel from '../utils/translated-label';
import {downloadAction} from '../../redux/download/download-action';

const log = require('../../libs/utils/log').create('DownloadButton');

/**
 * Generates the input field for the config value
 */
class DownloadButton extends Component {
  /**
   * Handles the click on the button
   */
  handleClick() {
    const {notifications, record, downloadAction, source, route} = this.props;
    log.info('Got the click, generating action');
    downloadAction(notifications, route, source, record);
  }

  /**
   * Standard render function
   * @return {xml}
   */
  render() {
    const props = this.props;
    const {icon, label} = props;
    const onClick = this.handleClick.bind(this);

    return <FlatButton label={<TranslatedLabel label={label}/>}
      icon={icon}
      onClick={onClick}
      style={{overflow: 'inherit'}}/>;
  }
}

DownloadButton.propTypes = {
  downloadAction: PropTypes.func,
  record: PropTypes.object,
  source: PropTypes.string,
  label: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  notifications: PropTypes.string.isRequired,
  icon: PropTypes.node,
};

export default connect(null, {downloadAction})(DownloadButton);
