/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 16.05.18.
 * Copyright © Dr. Huchler und Partner 2017
 */

import FIELD_TYPES from '../../consts/field-types';
import {minStringLength} from '../../forms/form-utils/field-validators';

export default {
  i18nPath: 'resources.tags.fields.',
  fields: {
    value: {
      type: FIELD_TYPES.TEXT_FIELD,
      validatorFunctions: minStringLength(1),
    },
    isProperty: {
      type: FIELD_TYPES.BOOLEAN,
    },
  },
};
