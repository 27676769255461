/**
 * Created by René Simon <rene.simon@dr-huchler-und-partner.de> on 04.10.18.
 * Copyright © Dr. Huchler und Partner 2017
 */

const log = require('./log').create('config');

let config = null;

export async function initConfig(client) {
  if (client && !config) {
    await updateConfig(client);
  } else {
    log.info(`Config already set`);
  }
}

export function isConfigInitialized() {
  return !!config;
}

export function resetConfig() {
  config = null;
}

async function updateConfig(client) {
  try {
    if (!client) {
      throw new Error('No config client given');
    }
    const {data: fetchedConfigs} = await client.get('/lms/configs?forFrontend=true');
    config = fetchedConfigs.reduce((memo, entry) => {
      memo[entry.key] = entry.value;
      return memo;
    }, {});
    log.info('Got config', config);
  } catch (error) {
    log.error('Failed to update config', error);
    throw error;
  }
}

export function getConfig(key) {
  if (!config) {
    throw new Error('Config not initialized');
  }
  return config[key];
}
